const environment ={
    BASE_URL:"https://lmsapi.cambridgeeducationlab.com/api"
    // BASE_URL:"http://127.0.0.1:8000/api"
}

if(process.env.REACT_APP_ENV=="staging")
{
    environment.BASE_URL="https://lmsapi.cambridgeeducationlab.com/api"
}
if(process.env.REACT_APP_ENV=="production")
{
    environment.BASE_URL="production"
}
export default environment;