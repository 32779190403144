import { useSelector } from 'react-redux'
import "./Dashboard.css"
import { useEffect, useState,useRef } from 'react'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import axios from "axios";
import environment from '../../environment';
import { ThreeCircles } from  'react-loader-spinner'
import Profile from '../Profile/Profile';
import {ToastContainer, toast } from 'react-toastify';
import {useNavigate } from 'react-router-dom'
import  myimg from "../../asserts/select.svg"
import  exportImg from "../../asserts/export.svg"
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IoTRoboRunner } from 'aws-sdk';


 
// import Animate from 'animate.css-react'
 
// import 'animate.css/animate.css' 

function DashboardComponent()
{
    const navigate = useNavigate();

    const userDetails = useSelector(state=>state.user.value)
    const isLoggedIn = useSelector(state=>state.login.value)

    
    let [navbar,setNavbar]=useState(true)
    let [selectedSession,setSelectedSession]=useState('')
    let [selectedModule,setSelectedModule]=useState({module:{id:0}})
    // let [selectedModule,setSelectedModule]=useState("Group Observation")
    let [selectedInvModule,setSelectedInvModule]=useState([])
    const [isOpenIndiChat, setisOpenIndiChat] = useState(false);
    const [isOpenGrpChat, setisOpenGrpChat] = useState(false);
    const [showDD,setShowDD]=useState(true)
    const [grpObservationModule,setGrpObservationModule] = useState('');
    const [indiNotes,setIndiNotes] = useState('');
    const [grpNotes,setGrpNotes] = useState('');
    const [dropDownOpen,setDropDownOpen] = useState(false);
    const [loader,setloader] = useState(false);
    const [profilePage,setProfilePage] = useState(false);
    const [clickMkSubmit,setClickMkSubmit] = useState(false);

    const [programDetails,setProgramDetails] = useState('');
    const [moduleList,setModuleList] = useState([]);
    const [selectedTask,selectedSelectedTask] = useState();
    const [selectedTaskArray,setSelectedTaskArray] = useState([{tasks:{}}]);
    const [paginator,setPaginator] = useState(0);
    const [taskSubmission,setTaskSubmission] = useState(null);
    const [modulePaginator,setModulePaginator] = useState(0);
    const [entireModuleCount,setEntireModuleCount] = useState(0);
    const [sessionCount,setSessionCount] = useState(0);
    const [grpModuleList,setGrpModuleList] = useState([]);
    const [grpSessionList,setGrpSessionList] = useState([]);
    const [grpSelectedSession,setGrpSelectedSession] = useState(0);
    const [grpSelectedModule,setGrpSelectedModule] = useState(0);
    const [currentSessionId,setCurrentSessionId] = useState(0);
    const [currentTaskId,setCurrentTaskId] = useState(0);
    const [grpObservationChat,setGrpObservationChat]=useState([]);
    const [selectedIndiModule,setSelectedIndiModule]=useState('');
    const [indiChatData,setIndiChatData]=useState([]);
    const [isSubmitted,setIsSubmitted]=useState(true);
    const [userId,setUserId]=useState(0)
    const [selectedIndichatId,setSelectedIndichatId]=useState(0)
    const [urlSubmit,setUrlSubmit]=useState(null)
    const [taskId,setTaskId]=useState(null)
    const [taskStatus,setTaskStatus]=useState(null)
    const [taskUrl,setTaskUrl]=useState(null)
    const [taskIsValid,setTaskIsValid]=useState(null)
    const [selectNxtModule,setSelectNxtModule] = useState()
    let [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
    const   getData  = async()=>
        {
            let data={
                user_id:userId,
                role_id:3
            }
        let da = await axios.post(environment.BASE_URL+'/all-data',data).then((response)=>
        {
            
            console.log("response",response.data.data[0])
            setProgramDetails(response.data.data[0].programme)
            setModuleList(response.data.data[0].modules)
            setGrpModuleList(response.data.data[0].modules)
            setGrpSessionList(response.data.data[0].modules[0].sessions)
            setSelectedInvModule(response.data.data[0].modules[0].sessions)
            setSelectedModule(response.data.data[0].modules[0])
            setCurrentSessionId(response.data.data[0].modules[0].sessions[0].session.id)
            setSelectedIndiModule(response.data.data[0].modules[0].module.id)
            console.log(response.data.data[0].modules[0].module.id,"sessions")
            setSelectedSession(response.data.data[0].modules[0].sessions[0].session.id)
            setSelectedTaskArray(response.data.data[0].modules[0].sessions[0].tasks)
            setSessionCount(0)
            setloader(false)
            getGrpChat(response.data.data[0].modules[0].module.id);
            getIndiChat(response.data.data[0].modules[0].module.id);
            setSelectedIndichatId(response.data.data[0].modules[0].module.id);
            setModuleTasksCheckList(response.data.data[0])
        }).catch((error)=>  {
        console.log(error)
        setloader(false)})
    }
    function setModuleTasksCheckList(data)
    {
        let finalList=[];
    
            data.modules.map((modData)=>{
                let sessArray=[]
                modData.sessions.map((sesData)=>{
                    let taskArray=[];
                    sesData.tasks.map((taskData)=>{
                        if(taskData.tasks.task_status=='completed')
                            taskArray.push(1)
                        else
                            taskArray.push(0)
                    })
                    if(taskArray.length==0)
                        taskArray.push(1)
                    sessArray.push(taskArray)
                })
                finalList.push(sessArray)
            })
        setSelectNxtModule(finalList)
    }

    function checkNxtModuleMove(currentModId,targetModule=null)
    {
        
        let flag=0;
        let temp=-1;
        
        console.log(selectNxtModule[currentModId])
        selectNxtModule[currentModId].map((sessions)=>{
            sessions.map((tasks)=>
            {
                if(tasks==0)
                flag=1
            })
        })
        if(targetModule!=null){
            temp=0
            selectNxtModule[targetModule].map((sessions)=>{
                sessions.map((tasks)=>
                {
                    if(tasks==0)
                    temp=1
                })
            })
        }
        console.log(flag,temp,selectNxtModule)
        if(targetModule!=null && Math.abs(currentModId-targetModule)>1)
        {
            if(flag==0 && temp==0)
                return 0;
            else if(flag==1 && temp==0 )
                return 0
            else if(flag==0 && temp==1)
                return 1
        }
        if(flag==1 && temp==1){
            return 1;}
        else if(flag==0 && temp==0){
            return 0;}
        else if(flag==0 && temp ==1){
            return 0;}
        else if(flag==1 && temp==0){
            return 0}
        else if(flag==1 && temp==-1)
            return 1;
        else if(flag==0 && temp==-1)
            return 0;
    }

    function clickToDownload(id,dataa)
    {
        let data={
            task_id:id
        }
        axios.post(environment.BASE_URL+'/get-task-files',data,{responseType: 'arraybuffer'}).then((res)=>{
            console.log(res.data)
            let blob = new Blob([res.data], {
                type: 'application/pdf'
            })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = dataa.title+'.pdf'
            link.click()
            // var blob = new Blob([res.data], {
            //     type: "application/pdf"
            //   });
            // const pdf = new jsPDF();
            // const textLines = pdf.splitTextToSize(blob, 280); // Adjust width as needed
            // pdf.text(textLines, 10, 20);
            // pdf.save(dataa.title+'.pdf');
            // var blob = new Blob([res.data], {
            //     type: "application/pdf"
            //   });
            //   const link = document.createElement('a');
            //   link.setAttribute('href', 'data:"application/pdf;base64,' + blob);
            // //   link.href = window.URL.createObjectURL(blob);
            //   link.download = 'test.pdf';
            //   link.click();
            //   window.URL.revokeObjectURL(link.href);
            
        }).catch((err)=>console.log(err))
        // const file = new Blob(['Hello, world!'], { type: 'text/plain;charset=utf-8' });
        // saveAs(file, 'hello_world.txt');
    }
    function getLocalData()
      {
        let localData=localStorage.getItem("LMS_DATA")
        if(localData)
        {
            localData=JSON.parse(localData)
            setUserId(localData.userId)
        }
      }
    useEffect(()=>
    {
        getLocalData();
        if(!isLoggedIn.isLoggedIn)
            navigate('/')
        if(userId!=0)
            getData();
        setloader(true)
        
    },[userId])
    // console.log(selectedTaskArray[paginator].tasks.title)
    const ref = useRef(null);
    window.onscroll = function() {scrollFunction()};
    const user = useSelector(state=>state.user.value)
    const scrollRef=useRef(null);
    const MoveRight=()=>{
        scrollRef.current.scrollLeft += 120;
                    
    }
    function getIndiChat(moduleId=null)
    {
        setloader(true)
        var data={
            user_id:userId,
            module_id:moduleId
        }
        axios.post(environment.BASE_URL+'/list-individual-observation',data).then((response)=>
        {
            setloader(false)
            setIndiChatData(response.data.data)
            console.log(response.data.data.length,"hjchdchdcbhcddc")
        }).catch((err)=>{
            setloader(false)
            console.log(err)
        })
    }
    function getGrpChat(moduleId=null)
    {
        setloader(true)
        let data={
            user_id:userId,
            module_id:moduleId==null?selectedSession:moduleId
        }
        axios.post(environment.BASE_URL+'/list-group-observation',data).then((responsee)=>{
            console.log(responsee.data.data)
            setGrpObservationChat(responsee.data.data)
            setloader(false)
        }).catch((err)=>{
            setloader(false)
            console.log(err)
        })
    }
    function handleGrpModuleChange(module)
    {
        // setGrpSessionList(JSON.parse(module));
        if(module)
        {
            // var sessionId=JSON.parse(module)[0].session.id;
            var moduleId=module;
            
            // console.log(sessionId)
            getGrpChat(moduleId)
            // setCurrentSessionId(sessionId)
        }
    }
    function handleGrpSessionChange(sessionId)
    {
        setloader(true)
        let data={
            user_id:userId,
            module_id:sessionId
        }
        setCurrentSessionId(sessionId)
        axios.post(environment.BASE_URL+'/list-group-observation',data).then((response)=>{
            console.log(response)
            getGrpChat(sessionId)
            setloader(false)
        }).catch((err)=>{
            console.log(err)
            setloader(false)
        })
    }
    function handleGrpObservation()
    {
        if(grpNotes.length<=0)
        {
            toast.warn("Please a your Observation")
            return;
        }
        setloader(true)
        console.log(selectedModule)
        let data={
            user_id:userId,
            module_id:selectedModule.module.id,
            session_id:currentSessionId,
            body:grpNotes,
            title:"aaaaaaa"
        }
        axios.post(environment.BASE_URL+'/add-observation',data).then((response)=>{
            console.log(response)
            setloader(false)
            getGrpChat(selectedModule.module.id)
            setGrpNotes('')
            toast.success("Observation added successfully")

        }).catch((err)=>{
            console.log(err)
            setloader(false)
        })
    }
    function handleAddNotes()
    {
        if(indiNotes.length<1)
        {
            toast.warn("Please add Notes")
            return;
        }
        setloader(true)
        console.log("modue is",selectedIndiModule)
        let data={
            user_id:userId,
            module_id:selectedModule.module.id,
            task_id:currentTaskId,
            session_id:currentSessionId,
            body:indiNotes,
            title:"aaaaaaa"
        }
        axios.post(environment.BASE_URL+'/add-observation',data).then((response)=>{
            console.log(response)
            getIndiChat(selectedModule.module.id)
            setSelectedIndichatId(selectedModule.module.id)
            setIndiNotes('')
            setloader(false)
            toast.success("Notes added successfully")
        }).catch((err)=>{
            console.log(err)
            setloader(false)
        })
    }
    function toggleGrpChat(id) {
        setisOpenGrpChat(!isOpenGrpChat);
        setCurrentSessionId(id)

      }
    function toggleIndiChat(sessionId,taskId) {
        setCurrentSessionId(sessionId)
        setCurrentTaskId(taskId)
    setisOpenIndiChat(!isOpenIndiChat);
    }
    function goback()
    {
        setProfilePage(false)
    }
    function handelDownload(data,type)
    {
    
        let body;
        let content;
        if(type=='indi')
        {
            body=[['Session Name','Task Name','Learning']];
            data.map((sessionData)=>{
                sessionData.map((invData)=>{
                    let temp=[];
                    temp.push(invData.session_title);
                    temp.push(invData.task_name);
                    temp.push(invData.title);
                    body.push(temp)
                })
            })
            content={
                layout: 'lightHorizontalLines', // optional
                table: {
                headerRows: 1,
                widths: [ '*', '*', '*' ],
                body: body
                }
            }
        }
        else if (type=='grp')
        {
            body=[['Name','School','Session Name','Learning']];
            data.map((grpData)=>{
                grpData.map((invData)=>{
                    let temp=[];
                    temp.push(invData.name);
                    temp.push(invData.school_name);
                    temp.push(invData.title);
                    temp.push(invData.body);
                    body.push(temp)
                })
            })
            content={
                layout: 'lightHorizontalLines', // optional
                table: {
                headerRows: 1,
                widths: [ '*', '*', '*','*'],
                body: body
                }
            }
        }
            const pdfData = {
                content: [
                    content
                ], 
                styles: {
                    header: {
                        fontSize: 22,
                        bold: true
                    },
                }
            }
            
        const pdfFile = pdfMake.createPdf(pdfData, null, null, pdfFonts.pdfMake.vfs);
        if(type=='indi')
        pdfFile.download('Individual-Notes.pdf');
        else
        pdfFile.download('Group-Observations.pdf');

    } 
    function closeProfilePage(email,currPassword,newPassword)
    {
        setloader(true)
        console.log(currPassword.length,newPassword.length)
        if(currPassword.length==0 || newPassword.length==0)
        {
            toast.warn("Please enter the Fields")
            setloader(false)
            return
        }
        let data={
            email:email,
            current_password:currPassword,
            new_password:newPassword
        }
        axios.post(environment.BASE_URL+'/reset-password',data).then((res)=>{
            setloader(false)
            toast.success("Profile Updated SuccessFully")
            setProfilePage(false)
        }).catch((err)=>{toast.error(err.response.data.message);        setloader(false)
    })      
    }
    const MoveLeft=()=>{
        scrollRef.current.scrollLeft -= 120;
    }
    const moveDown =()=>{
        ref.current?.scrollIntoView({behavior: 'smooth'});
      }
    function handleProfile()
    {
        setProfilePage(true)
        setDropDownOpen(!dropDownOpen)
    }
    function handleLogout()
    {
        setDropDownOpen(!dropDownOpen)
        localStorage.removeItem("LMS_DATA")
        localStorage.removeItem("LMS_ROLE")
        navigate("/")
    }
    function scrollFunction() {
        console.log(document.body.scrollTop )
        if ( document.documentElement.scrollTop < 150) {
         setShowDD(false)
        } else {
          setShowDD(true)
        }
      }

    function markAsCompleted(taskId,isUpload,status,urlUpload)
    {
        selectedTaskArray[paginator].tasks.uploded=true;
        if(status=='completed')
        {
            toast.warn("Task Already Submitted")
            setClickMkSubmit(false)
            handleClose()
            return;
        }
       let config;
       if(urlUpload=='true')
       {
        if(!urlSubmit || urlSubmit.length<1)
        {
            toast.warn("Please Enter the Url")
            setClickMkSubmit(false)
            handleClose()
            return
        }
        }
        if(taskSubmission==null && isUpload!=0 && urlUpload=='false')
        {
            toast.error("Please select the file")
            setClickMkSubmit(false)
            handleClose()
            return
        }
        if(isUpload==1 && urlUpload=='false')
        {
            config = {
                headers: {
                  'content-type': 'multipart/form-data',
                },
              };
            var data={
                user_id:userId,
                task_id:taskId,
                file:taskSubmission,
                url_upload:null
            }
        }
        else if(isUpload==0 && urlUpload=='true')
        {
            var data={
                user_id:userId,
                task_id:taskId,
                file:null,
                url_upload:urlSubmit
            }
        }
        else if(isUpload==0 && urlUpload=='false' )
        {
            var data={
                user_id:userId,
                task_id:taskId,
                file:null,
                url_upload:null
            }
        }
        else{
            var data={
                user_id:userId,
                task_id:taskId,
                file:null,
                url_upload:urlSubmit
            }
        }
        setloader(true)
        console.log(data)
        axios.post(environment.BASE_URL+'/complete-task',data,config).then((response)=>
        {
            toast.success("Task Submitted Successfully")
            setTaskSubmission(null)
            setUrlSubmit(null)
            setClickMkSubmit(false)
            handleClose()
            let val= document.getElementById('urlupload')?.value;
            if(val)
            document.getElementById('urlupload').value=null
            var flag=[...selectedTaskArray];
            flag.map((data,index)=>
            {
                if(index==paginator)
                {
                    data.tasks.task_status="completed"
                }
            })
            setSelectedTaskArray(flag)
            var temp=[...moduleList]
            temp.map((mData,index)=>{
                if(index==modulePaginator)
                {
                    mData.sessions[sessionCount].tasks[paginator].tasks.task_status="completed"
                }
            })
            let checkList=[...selectNxtModule];
            checkList[modulePaginator][sessionCount][paginator]=1
            setSelectNxtModule(checkList)
            setModuleList(temp)
            setTaskSubmission(null)
            setloader(false)
            handleNext()

            setIsSubmitted(true)
        }).catch((err)=>
        {
            console.log(err)
            handleClose()
            toast.error('Something went wrong !')
            setloader(false)
        })
    }
    function handleNext()
    {
    
        console.log(selectNxtModule)
        console.log(entireModuleCount,moduleList.length)
        console.log(sessionCount,moduleList[modulePaginator],moduleList[modulePaginator].sessions.length-1)
        var taskCount=selectedTaskArray.length
        setTaskSubmission(null)
        if(paginator<taskCount-1)
        {
            setPaginator(paginator+1)
        }
        else
        {
            if(sessionCount<moduleList[modulePaginator].sessions.length-1)
            {
                setSessionCount(sessionCount+1)
                setSelectedTaskArray(moduleList[modulePaginator].sessions[sessionCount+1].tasks)
                setSelectedSession(moduleList[modulePaginator].sessions[sessionCount+1].session.id)
                setPaginator(0)
            }

            else if(entireModuleCount<moduleList.length-1)
            {
                // CHECK WEATHER ALL THE TASKS IN THE MODULE IS COMPLETED
                if(checkNxtModuleMove(modulePaginator)){
                    toast.warn("Please complete all the Tasks in the Current Module.")
                    return;
                }
                else{
                    setModulePaginator(modulePaginator+1)
                    setSelectedModule(moduleList[modulePaginator+1])
                    console.log(moduleList[modulePaginator+1].sessions)
                    setSelectedInvModule(moduleList[modulePaginator+1].sessions)
                    setSelectedTaskArray(moduleList[modulePaginator+1].sessions[0].tasks)
                    setSelectedSession(moduleList[modulePaginator+1].sessions[0].session.id)
                    setPaginator(0)
                    setSessionCount(0)
                    setEntireModuleCount(entireModuleCount+1)
                }
                // setIsSubmitted(false)
            }
        }
        console.log(selectNxtModule)

    }
    function handlePrev()
    {
        if(paginator!=0)
        {
            setPaginator(paginator-1)
            // setIsSubmitted(true)
        }
            else
            {
                if(sessionCount>0)
                {
                    setSessionCount(sessionCount-1)
                    setSelectedTaskArray(moduleList[modulePaginator].sessions[sessionCount-1].tasks)
                    setSelectedSession(moduleList[modulePaginator].sessions[sessionCount-1].session.id)
                    setPaginator(0)
                    // setIsSubmitted(true)
    
                }
    
                else if(entireModuleCount>0)
                {
                    setModulePaginator(modulePaginator-1)
                    setSelectedModule(moduleList[modulePaginator-1])
                    console.log(moduleList[modulePaginator-1].sessions)
                    setSelectedInvModule(moduleList[modulePaginator-1].sessions)
                    setSelectedTaskArray(moduleList[modulePaginator-1].sessions[0].tasks)
                    setSelectedSession(moduleList[modulePaginator-1].sessions[0].session.id)
                    setPaginator(0)
                    setSessionCount(0)
                    setEntireModuleCount(entireModuleCount-1)
                    // setIsSubmitted(true)
                }
            }
    }
    function deletePopUp(message)
    {
      return(
          <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
          backdrop: {
              timeout: 500,
          },
          }}
      >
          <Fade in={open}>
          <Box sx={style}>
              <Typography id="transition-modal-title" variant="h6" component="h6" style={{fontSize:"18px",textAlign:'center'}}>
              {message}
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  <div style={{display:'flex',justifyContent:"space-around"}}>
                      <div className='deleteConfirm' onClick={()=>handelDelete()}>YES</div>
                      <div className='deleteConfirm' onClick={()=>handleClose()} >NO</div>
                  </div>
              </Typography>
          </Box>
          </Fade>
      </Modal>
      )
    }
    function handelDelete(){
        markAsCompleted(taskId,taskIsValid,taskStatus,taskUrl)
    }
    function handleMarkAsCompleted(status)
    {
        if(status!='completed')
        {
            setClickMkSubmit(!clickMkSubmit)
        }
    }
    function handleChangeModule(module,sessions,index)
    {
        if(checkNxtModuleMove(modulePaginator,index)){
            toast.warn("Please complete all the Tasks in the Current Module.")
            return;
        }
        setSelectedModule(module);
        setSelectedInvModule(sessions);
        console.log(index)
        setSelectedTaskArray(sessions[0].tasks)
        setSelectedSession(sessions[0].session.id)
        setEntireModuleCount(index)
        setModulePaginator(index)
        setSelectedModule(moduleList[index])
        setSelectedInvModule(moduleList[index].sessions)
        setSelectedTaskArray(moduleList[index].sessions[0].tasks)
        setSelectedSession(moduleList[index].sessions[0].session.id)
        setPaginator(0)
    }
    return (
        <div style={{display:"flex",flexDirection:"column"}} >
            {deletePopUp('Are you sure you want to submit the Task ?')}
             {
            loader?
            <div className='fullpageloader'>
               <ThreeCircles
                height="100"
                width="100"
                color="#4fa94d"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="three-circles-rotating"
                outerCircleColor=""
                innerCircleColor=""
                middleCircleColor=""
              />
            </div>
            :null
         }
            <div className='dashboardHeader'>
            <div className='logo removed'>
                    <img src={require('../../asserts/logo.webp')} className='logoimg'/> 
                    </div>
                <img src={require("../../asserts/profile.png")} style={{width:'2rem'}} onClick={()=>{setDropDownOpen(!dropDownOpen)}}/>
            </div>
            {  
            dropDownOpen?
            <div className='profileBar'>
                <div onClick={()=>handleProfile()} > <img src={require("../../asserts/profile.png")} style={{width:'1.2rem',marginRight:"10px"}}/> My Profile</div>
                <div  onClick={()=>handleLogout()}> <img src={require("../../asserts/logout.png")} style={{width:'1.2rem',marginRight:"10px"}}/>Logout</div>
            </div>:null
            }
        <div className='containerrr' >

           
            <ToastContainer autoClose={2000} closeOnClick={true} pauseOnHover={false}  />
         
            
            { 
            navbar ?
                <div className='navContainerr' >
                
                    <div className='moduleHeader' style={{flexDirection:"column"}}>
                   
                    <p className='title' style={{color:"#fdd447"}}>{programDetails.title}</p>
                    </div>
                    <div className='closeBar'>
                        <img src={require("../../asserts/close-white.png")} style={{ width:"35px",opacity:0.5}} onClick={()=>setNavbar(false)}/>
                    </div>
                    <div className='moduleHeader'>
                    <img src={require("../../asserts/modules.png")} style={{width:'53px',height:"34px"}} />
                   
                    <p className='title'>Modules</p>
                    </div>
                    <div className='moduleContainer removed' style={{height:"20rem", minHeight:'20rem',maxHeight:'none',justifyContent:"flex-start"}}>
                    { 
                    
                    moduleList.map((module,index)=>
                    {
                    return ( <div className={selectedModule!='Individual Notes' && selectedModule!='Group Observation' &&  selectedModule.module.id==module.module.id? 'modules selectedModule' : 'modules'} onClick={()=>{ handleChangeModule(module,module.sessions,index);}}  >
                        {module.module.title}
                        </div>
                    )
                    })
                    }
                    </div>
                    <div className='chatContainer' >
                    <div className={selectedModule =='Individual Notes'?' selectedModulee individualNotes modules' :"individualNotes modules"} onClick={()=>setSelectedModule('Individual Notes')}>
                    {selectedModule =='Individual Notes'?
                    <img src={require("../../asserts/person-black.png")} style={{width:'15px',height:'20px',marginRight:'5px'}}/> 
                    :
                    <img src={require("../../asserts/person.png")} style={{width:'15px',height:'20px',marginRight:'5px'}}/> 
                    }
                    <p className='removed'> Individual Notes</p>
                    </div>
                    <div className={selectedModule =='Group Observation'?' selectedModulee individualNotes modules' :"individualNotes modules"} onClick={()=>setSelectedModule('Group Observation')}>
                    {selectedModule =='Group Observation'?
                    <img src={require("../../asserts/group-black.png")}  style={{width:'20px',height:'20px',marginRight:'5px'}}/>
                    :
                    <img src={require("../../asserts/group.png")}  style={{width:'20px',height:'20px',marginRight:'5px'}}/>
                    }
                     <p className='removed'>Group Observation</p>
                    </div>
                    </div>
                </div>
            :
            <div className='hiddenNav'>
            <img src={require("../../asserts/menu.png")} style={{width:"40px",margin:'20px 0px'}} onClick={()=>setNavbar(true)}/>
            <Tooltip title="Modules" placement="right">
                    <IconButton>
                    <img src={require("../../asserts/modules.png")} style={{width:"25px",margin:'10px 0px'}}onClick={()=>setSelectedModule(moduleList[modulePaginator])}/>
                    </IconButton>
            </Tooltip>
            <Tooltip title="Individual Notes" placement="right">
                    <IconButton>
                    <img src={require("../../asserts/person-side.png")} style={{width:"25px",margin:'10px 0px'}} onClick={()=>setSelectedModule('Individual Notes')}/>
                    </IconButton>
            </Tooltip>
            <Tooltip title="Group Observation" placement="right">
                    <IconButton>
                    <img src={require("../../asserts/group-side.png")} style={{width:"25px",margin:'10px 0px'}}onClick={()=>setSelectedModule('Group Observation')}/>
                    </IconButton>
            </Tooltip>
        </div>
            }
               
            <div className=''  style={{width:"75%",flexGrow:1 }} >
         
           
            {
                profilePage?<Profile backPage={closeProfilePage} navigateBack={goback} />
                :
            <>
            {
                selectedModule != "Individual Notes" &&   selectedModule != "Group Observation" 
                ?
                <>
             
                <div className='test' onClick={()=>setDropDownOpen(false)}>
                    <Modal

                        isOpen={isOpenIndiChat}
                        onRequestClose={toggleIndiChat}
                        className="mymodal"
                        overlayClassName="myoverlay"
                        closeTimeoutMS={500}
                        contentLabel="My dialog"
                    >
                        <div className='indiContainer'>
                            <div className='closeModel'>
                                <img onClick={toggleIndiChat} src={require("../../asserts/close.png")}/>
                                {/* <button >Close modal</button> */}
                            </div>
                            <div style={{display:'flex',flexDirection:"column",flex:1}}>
                                <label style={{padding:'20px 0'}}>Add your Notes</label>
                                <textarea  onChange={(e)=>setIndiNotes(e.target.value)} ></textarea>
                            </div>
                            <div className='chatBtns'>
                                <div className='indiCancel' style={{alignSelf: 'center', padding: '1rem 0'}}>
                                    <button onClick={()=>{toggleIndiChat();}}>Cancel</button>
                                </div>
                                <div className='indiSubmit' style={{alignSelf: 'center', padding: '1rem 0'}}>
                                    <button onClick={()=>{toggleIndiChat();handleAddNotes()}}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        isOpen={isOpenGrpChat}
                        onRequestClose={toggleGrpChat}
                        className="mymodal"
                        overlayClassName="myoverlay"
                        closeTimeoutMS={500}
                        contentLabel="My dialog"
                    >
                        <div className='indiContainer'>
                            <div className='closeModel'>
                                <img onClick={toggleGrpChat} src={require("../../asserts/close.png")}/>
                                {/* <button >Close modal</button> */}
                            </div>
                            <div style={{display:'flex',flexDirection:"column",flex:1}}>
                                <label style={{padding:'20px 0'}}>Add your Observation</label>
                                <textarea  onChange={(e)=> setGrpNotes(e.target.value)}  > </textarea>
                            </div>
                            <div className='chatBtns'>
                                <div className='indiCancel' style={{alignSelf: 'center', padding: '1rem 0'}}>
                                    <button onClick={()=>{toggleGrpChat();}}>Cancel</button>
                                </div>
                                <div className='indiSubmit' style={{alignSelf: 'center', padding: '1rem 0'}}>
                                    <button  onClick={()=>{toggleGrpChat();handleGrpObservation()}}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                  
                    <>
                    <div className='sessionsContainer'  >
                        
                      <div className='imgConat' style={{borderRadius:' 20px 0 0 0'}} onClick={()=>MoveLeft()}>  <img style={{width:'2rem'}} src={require("../../asserts/left.png")} /></div>
                        <div className='horiScroll' ref={scrollRef}>
                        {
                            selectedInvModule.map((session,index)=>{
                         
                                return(
                                    <div className={ selectedSession==session.session.id ? ' highlight session':'session'} onClick={()=>{setSessionCount(index);setSelectedSession(session.session.id);console.log(session.tasks);setSelectedTaskArray(session.tasks);setPaginator(0)}} >
                                        {session.session.title}
                                    </div>
                                )
                            })
                        }
                        </div>
                        <div className='imgConat' style={{borderRadius:' 0px 20px 0 0'}}  onClick={()=>MoveRight()}>
                        <img src={require("../../asserts/right.png")} style={{width:'2rem'}}/>
                        </div>
                    </div>
                    {
                    
                    selectedTaskArray.length>0?
                    <div className='tasksContainer' style={{alignItems:"flex-start"}}>
                        <label className='taskTitles'>TITLE</label>
                        <div className='taskTitle'>
                        {
                             selectedTaskArray[paginator].tasks.title
                        }
                        </div>
                        <label className='taskTitles'>DESCRIPTION</label>
                        <div className='taskDescc'>
                        {
                             selectedTaskArray[paginator].tasks.description
                        }
                        </div>
                        <div className='taskDescc'>
                        {
                             selectedTaskArray[paginator].tasks.path ? 
                             <div className='taskAssignment' >
                                <div className='assignment'>
                                <label>ASSIGNMENT</label>
                                <div className='clicktoDownload' onClick={()=>clickToDownload(selectedTaskArray[paginator].tasks.id,selectedTaskArray[paginator].tasks)} >
                                  <img src={require("../../asserts/download.png")} className="downloadBtn" />
                                    <p style={{padding:"0px",}} >Click to Download</p>{/* <a href={""+selectedTaskArray[paginator].tasks.path}> click here to download</a> */}
                                </div>
                                </div>
                                { selectedTaskArray[paginator].tasks.url_upload=='false'?
                                <div className='assignment'>
                                <label>UPLOAD ASSIGNMENT</label>
                                <div className='uploadDiv'>
                                <img src={require("../../asserts/upload.png")} className="downloadBtn" />
                                    { taskSubmission ==null?
                                    <p style={{padding:"0px",}} >Upload File</p>
                                    :
                                    <p>{taskSubmission.name}</p>
}
                                <input className='uploadInput' onChange={(e)=>setTaskSubmission(e.target.files[0])} accept=".pdf"  type='file'/>
                                </div>
                                </div>
                                :
                                <div className='' style={{marginTop:"1rem"}}>
                                    <label className='taskTitles' style={{marginRight:"1rem"}} >Enter the Url : </label>
                                    <input type='text'onChange={(e)=>setUrlSubmit(e.target.value)} id='urlupload' value={urlSubmit?urlSubmit:null}/>
                                </div>
                            }
                            </div>:<p></p>
                        }
                        </div>
                        {
                            selectedTaskArray[paginator].tasks.path ?
                            <div className= { selectedTaskArray[paginator].tasks.task_status!='completed'? 'taskCompletion' : 'disabled taskCompletion'} onClick={()=>handleMarkAsCompleted(selectedTaskArray[paginator].tasks.task_status)} >
                              {
                                 selectedTaskArray[paginator].tasks.task_status!='completed'?
                                <>
                                { !clickMkSubmit? 
                                    <img src={require("../../asserts/inComplete.png")}  style={{width:"25px",marginRight:"10px"}} /> 
                                :
                                    <img src={myimg} style={{width:"25px",marginRight:"10px"}} /> 
                                }
                                </>                 
                                :
                             
                                   <img src={myimg} style={{width:"25px",marginRight:"10px"}} /> 

                              }Mark as Complete
                            </div>
                        :
                            <div className={ selectedTaskArray[paginator].tasks.task_status!='completed'? 'taskCompletion' : 'disabled taskCompletion'} onClick={()=>handleMarkAsCompleted(selectedTaskArray[paginator].tasks.task_status)} >
                                 {
                                 selectedTaskArray[paginator].tasks.task_status!='completed'?
                                 <>
                                 { !clickMkSubmit? 
                                     <img src={require("../../asserts/inComplete.png")}  style={{width:"25px",marginRight:"10px"}} /> 
                                 :
                                     <img src={myimg} style={{width:"25px",marginRight:"10px"}} /> 
                                 }
                                 </>                                :
                              
                                <img src={myimg} style={{width:"25px",marginRight:"10px"}} /> 
                                    

                              }Mark as Complete
                            </div>
                        } 
                          {
                            selectedTaskArray[paginator].tasks.path ?
                                <div className='nav-btn'style={{alignSelf:"end",marginTop:"2rem"}} onClick={()=>{handleOpen();setTaskId(selectedTaskArray[paginator].tasks.id);setTaskIsValid(1); setTaskStatus(selectedTaskArray[paginator].tasks.task_status);setTaskUrl(selectedTaskArray[paginator].tasks.url_upload)}}>Submit</div>
                            :
                                <div className='nav-btn' style={{alignSelf:"end",marginTop:"2rem"}} onClick={()=>{ handleOpen();setTaskId(selectedTaskArray[paginator].tasks.id);setTaskIsValid(0); setTaskStatus(selectedTaskArray[paginator].tasks.task_status);setTaskUrl(selectedTaskArray[paginator].tasks.url_upload)}}>Submit</div>
                          }
                         { selectedTaskArray.length>0?<>
                        <div className='addChatBtn' style={{marginTop:"1rem"}} onClick={()=>{toggleIndiChat(selectedTaskArray[paginator].tasks.session_id,selectedTaskArray[paginator].tasks.id)}} >Add Individual Notes</div>
                        {console.log("aaaaa",selectedTaskArray[selectedTaskArray.length-1].tasks.id)}
                        {
                            selectedTaskArray[paginator].tasks.id==selectedTaskArray[selectedTaskArray.length-1].tasks.id?
                        <div className='addChatBtn' style={{padding:"10px 53px"}} onClick={()=>toggleGrpChat(selectedTaskArray[paginator].tasks.session_id)}>Add Group Observation</div>
                        :
                        null
                        }
                        </>:null}
                    </div>
                    :
                    <div className='tasksContainer'> No Tasks Found</div>}

                    <div className='navigation'>
                        <div className='nav-btn' onClick={()=>handlePrev()} >Previous</div>
                       
                        <div className= {isSubmitted? 'nav-btn' :' disabled nav-btn'} onClick={()=>handleNext()} >Next</div>
                    </div>
                    </>
                  
                </div>
                </>
                :
                <div className='chatModule' ref={ref} onClick={()=>setDropDownOpen(false)}>
                   {
                    selectedModule=='Individual Notes'?
                        <div className='indNotesModule'>
                            <p className='chatHeader'>Individual Notes</p>
                            <div className='modulesGrid'>
                                {
                                    grpModuleList.map((module)=>
                                    {
                                      
                                           return(
                                            <option  className= {selectedIndichatId==module.module.id ? ' selectedIndiChat individualModule': 'individualModule'} onClick={()=>{getIndiChat(module.module.id);setSelectedIndichatId(module.module.id)}} value={ module.module.id}>{module.module.title}</option>
                                            )
                                      
                                    })
                                }
                            </div>
                            <div style={{display:"flex",justifyContent:"space-between",alignItems:"baseline",width:"100%"}}>
                                <p className='myLearnings' >My Learnings</p>
                                <div className='downloadPdf' onClick={()=>handelDownload(indiChatData,'indi')} >
                                    <img src={exportImg} style={{width:"20px",marginRight:"10px"}}/>
                                    Export
                                </div>
                            </div>
                            {
                            indiChatData.length>0?
                            <div style={{width:"100%",marginTop:"20px"}}>
                                 {
                                    indiChatData.map((chat)=>
                                    {
                                        return(
                                            <>
                                            <label className='sessionNameContainer'>{chat[0].session_title}</label>
                                            <div className='grpObservationChat'>
                                            {
                                                chat.map((data,index)=>{
                                                    return(
                                                        <div className='chatConatiner' style={{padding:"10px"}}>
                                                            <div style={{display:'flex',alignItems:"center",marginBottom: '10px',height:'1rem'}}>

                                                            </div>
                                                            <div>
                                                            <div className='chatDesc'>{index+1}) {data.body}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                
                                            }
                                            </div>
                                            </>
                                        )
                                    })
                                }
                                
                                 </div>
                            // <div className='indiChat'>
                            //     <div className='header'>
                            //         <div className='sessionName' style={{fontSize:'1em',background: 'gainsboro',    }}>
                            //             Task Name
                            //         </div>
                            //         <div className='Learnings' style={{fontSize:'1em',textAlign:"center",background: 'gainsboro'}}>
                            //             Learnings
                            //         </div>
                            //     </div>
                              
                            //         {
                            //             indiChatData.map((chat)=>
                            //             {
                            //                 return(
                            //                     <div className='body'>
                            //                         <div className='sessionName'>
                            //                             {chat.task_name}
                            //                         </div>
                            //                         <div className='Learnings'>
                            //                             {chat.body}
                            //                         </div>
                            //                     </div>
                            //                 )
                            //             })
                                    
                                   
                            //     }
                                
                            // </div>
                            :
                            <div style={{marginTop:"3rem"}}>No Notes Found</div>
                            }
                        
                        </div>
                    :
                        <div className='grpObservation'>
                              <div className='moduleSelection'>
                                <label style={{fontSize:'1em',fontWeight:600}}>Select a Module</label>
                                <select className='moduleOption'  onChange={(e)=>handleGrpModuleChange(e.target.value)}  >
                                {/* <option selected style={{textAlign:"center"}}> --- Select a Module ----</option> */}
                                {
                                    grpModuleList.map((module)=>
                                    {
                                       return(
                                        <option  value={module.module.id}>{module.module.title}</option>
                                        )
                                    })
                                }
                                </select>
                              </div>
                              {/* <div className='sessionModule' style={{marginTop:'1rem'}}>
                              {
                                    grpSessionList.map((session)=>
                                    {
                                        return(
                                        <div className= { currentSessionId==session.session.id? ' selectedIndiChat individualModule' : 'individualModule'} onClick={()=>handleGrpSessionChange(session.session.id)} >{session.session.title}</div>
                                        )
                                    })
                                }
                              </div> */}
                            <div style={{display:"flex",justifyContent:"space-between",alignItems:"baseline",width:"100%"}}>
                                <p className='myLearnings' >My Observations</p> 
                                <div className='downloadPdf' onClick={()=>handelDownload(grpObservationChat,'grp')} >
                                    <img src={exportImg} style={{width:"20px",marginRight:"10px"}}/>
                                    Export
                                </div>
                            </div>
                              {
                                grpObservationChat.length>0 ?
                                 <div style={{width:"100%",marginTop:"20px"}}>
                                 {
                                    grpObservationChat.map((chat)=>
                                    {
                                        return(
                                            <>
                                            <label className='sessionNameContainer'>{chat[0].title}</label>
                                            <div className='grpObservationChat'>
                                            {
                                                chat.map((data)=>{
                                                    return(
                                                        <div className='chatConatiner'>
                                                            <div style={{display:'flex',alignItems:"center",marginBottom: '10px',height:'1rem'}}>
                                                            <div className='chatName'>{data.name}</div>
                                                            <div className='chatDate'>( {data.designation} ,</div>  
                                                            <div className='chatDate'>{data.school_name})</div>
                                                            </div>
                                                            <div>
                                                            <div className='chatDesc'>{data.body}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                
                                            }
                                            </div>
                                            </>
                                        )
                                    })
                                }
                                
                                 </div>
                            






                            //   <div className='grpObservationChat'>
                            //     {
                            //         grpObservationChat.map((chat)=>
                            //         {
                            //         return(
                            //             chat.map((data,index)=>
                            //             {
                            //                 return(
                            //                     <>
                            //                     {
                            //                         index==0?
                            //                             <div className='sessionNameContainer'>{data.title}</div>
                            //                         :null
                            //                     }
                                                // <div className='chatConatiner'>
                                                //     <div style={{display:'flex',alignItems:"center",marginBottom: '10px',height:'1rem'}}>
                                                //     <div className='chatName'>{data.name}</div>
                                                //     <div className='chatDate'>( {data.designation} ,</div>  
                                                //     <div className='chatDate'>{data.school_name})</div>
                                                //     </div>
                                                //     <div>
                                                //     <div className='chatDesc'>{data.body}</div>
                                                //     </div>
                                                // </div>
                            //                 </>
                            //                 )
                            //             })
                            //             )
                            //         })
                                    
                            //     }
                            //   </div>
                        //     <div className='indiChat'>
                        //     <div className='header'>
                        //         <div className='sessionName' style={{fontSize:'1em',background: 'gainsboro',    }}>
                        //             Teacher
                        //         </div>
                        //         <div className='sessionName' style={{fontSize:'1em',background: 'gainsboro',    }}>
                        //             Date
                        //         </div>
                        //         <div className='Learnings' style={{fontSize:'1em',textAlign:"center",background: 'gainsboro'}}>
                        //             Learnings
                        //         </div>
                        //     </div>
                          
                        //         {
                        //             grpObservationChat.map((chat)=>
                        //             {
                        //                 return(
                        //                     <div className='body'>
                        //                         <div className='sessionName' style={{minHeight:"4rem"}} >
                        //                             {chat.name}
                        //                         </div>
                        //                         <div className='sessionName' style={{minHeight:"4rem"}}>
                        //                             {chat.created_at.substring(0, 10)}
                        //                         </div>
                        //                         <div className='Learnings'>
                        //                             {chat.body}
                        //                         </div>
                        //                     </div>
                        //                 )
                        //             })
                                
                               
                        //     }
                            
                        // </div>
                              :
                              <div className='grpObservationChat'>No Observations Found</div>
                            }
                        </div>
                   }
                </div>
            }
            </>}
            
            </div>
           
        </div>  
        </div>
    )
}

export default DashboardComponent;