import React, { useState } from 'react'
import {  useSelector } from 'react-redux'
import { useEffect} from 'react'
import axios from 'axios';
import environment  from '../../environment' ;
import { ToastContainer, toast } from 'react-toastify';
import "./User.css"

function User({navigateBack}) {
  const user = useSelector(state=>state.editUser.value)
  const [programme,setProgramme]=useState([]);
  const [name,setName]=useState(user.name);
  const [emailid,setEmailId]=useState(user.email);
  const [role,setRole]=useState(user.role);
  const [status,setStatus]=useState(user.status);
  const [program,setProgram]=useState(user.programme);

  useEffect(()=>{
    axios.get(environment.BASE_URL+'/programme').then((response)=>
    {
      let temp=[...response.data.data];
      setProgramme(temp)
    }) .catch(error => {
      console.error(error);
    });
  },[user])

 
  function goback ()
  {
    navigateBack(true)
  }
  function updateDetails()
  {
    toast.success("Details Updated")
    navigateBack(true)
  }
  console.log(user)
  return (
    <div className='editContainer' >
        <ToastContainer autoClose={2000} closeOnClick={true} pauseOnHover={false} />
      <div className='editHeader'>
        <img style={{width:'1.5rem'}} onClick={()=>goback()} src={require("../../asserts/left.png")}/>
        <label>Edit User</label>
      </div>
      <div style={{display:"flex",width:"100%"}} className='usermobile' >
        <div className='editBody'>
            <div className='editinput'>
              <label>Name</label>
              <input type='text' value={name} disabled onChange={(e)=>setName(e.target.value)} />
            </div>
        </div>
        <div className='editBody'>
            <div className='editinput'>
              <label>Email Id</label>
              <input type='email' value={emailid} disabled onChange={(e)=>setEmailId(e.target.value)}/>
            </div>
        </div>
      </div>
      <div style={{display:"flex",width:"100%"}} className='usermobile'>
        <div className='editBody' >
            <div className='editinput'>
              <label>Role</label>
              <select value={role} disabled onChange={(e)=>setRole(e.target.value)}>
                <option value={'Trainer'}>Trainer</option>
                <option value={'Learner'}> Learner</option>
              </select>
            </div>
        </div>
        <div className='editBody' >
            <div className='editinput'>
              <label>Status</label>
              <select value={status} disabled onChange={(e)=>setStatus(e.target.value)}>
                <option value={'Active'}>Active</option>
                <option value={'Inactive'}> Inactive</option>
              </select>
            </div>
        </div>
      </div>
      <div className='editBody' >
            <div className='editinput'>
              <label>Programme</label>
              <select value={program} disabled onChange={(e)=>setProgram(e.target.value)}>
                {
                  programme.map((data)=>
                  {
                    return(
                      <option key={data.id} value={data.id}>{data.description} ({data.duration})</option>
                    )
                  })
                }
              </select>
            </div>
        </div>

          <div className='editUpdate'>
            <button onClick={()=>updateDetails()} >Update</button>
          </div>

    </div>
  )
}

export default User