import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom'
import { configureStore } from "@reduxjs/toolkit"
import { Provider} from 'react-redux'

import './index.css';
import App from './App';
import userSlice from './features/User';
import  edituserSlice  from './features/EditUser';
import dataSlice from './features/Data'
import  loginSlice  from './features/Login';

const root = ReactDOM.createRoot(document.getElementById('root'));
const store = configureStore({
  reducer:{
    user:userSlice,
    editUser:edituserSlice,
    data:dataSlice,
    login:loginSlice
  }
})
root.render(
  <Provider store={store}>
  
  <HashRouter>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </HashRouter>
  </Provider>
);


