import React, { useState,useEffect } from 'react'
import "./TeacherDashboard.css"
import {  useSelector } from 'react-redux'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import environment  from '../../environment' ;
import { ThreeCircles } from  'react-loader-spinner'
import { Columns, PdfMakeWrapper,Table as pdftable, Txt } from 'pdfmake-wrapper';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import  myimg from "../../asserts/export.svg"

function ProgrammeStatus({loaderFun }) {


    const [programmeList,setProgrammeList]=useState([])
    const [moduleList,setModuleList]=useState([])
    const [sessionList,setSessionList]=useState([])
    const [taskList,setTaskList]=useState([[]])
    const [allData,setAllData]=useState();
    const [selectedProgrammeModules,setSelectedProgrammeModules]=useState();
    const [selectedProgrammeId,setSelectedProgrammeId]=useState();
    let [loader,setloader]=useState(false)
    let [userId,setUserId]=useState(0)


    const user = useSelector(state=>state.data.value)
    function createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
      }
      function getLocalData()
      {
        let localData=localStorage.getItem("LMS_DATA")
        if(localData)
        {
            localData=JSON.parse(localData)
            console.log("uesersssss ud",localData.userId)
            setUserId(localData.userId)
        }
      }

      function getData(){
        let data={
            user_id:userId,
            role_id:2
        }
        axios.post(environment.BASE_URL+'/all-data',data).then((response)=>{
            let res=response.data.data;
            setAllData(res);
            let temp=[];
            res.map((val)=>
            {
                temp.push(val.programme)
            })
            setProgrammeList(temp)
            loaderFun(false)
        }).catch(err=>console.log(err))
      }

      function handelDownload()
      {
        
    //    let body=[{text:['Name','Task Name','Status'],style:'header'}];
       let body=[['Name','Task Name','Status']];
        taskList.map((taskData)=>{
            taskData.map((invData)=>{
                let temp=[];
                temp.push(invData.username);
                temp.push(invData.task_name);
                temp.push(invData.status);
                body.push(temp)
            })
        })
        const pdfData = {
            content: [
                {
                    layout: 'lightHorizontalLines', // optional
                    table: {
                      // headers are automatically repeated if the table spans over multiple pages
                      // you can declare how many rows should be treated as headers
                      headerRows: 1,
                      widths: [ '*', '*', '*' ],
              
                      body: body
                    }
                  }
            ], 
            styles: {
                header: {
                  fontSize: 22,
                  bold: true
                },
          }
        }
        
          const pdfFile = pdfMake.createPdf(pdfData, null, null, pdfFonts.pdfMake.vfs);
        
          pdfFile.download('Report.pdf');
      } 
      useEffect(()=>{
        var UserData=localStorage.getItem('createAccount')
        if(UserData)
        {
            
        }
        loaderFun(true)
        getLocalData() 
        if(userId!=0)
            getData()
     
      },[userId])
      const rows = [
        createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
        createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
        createData('Eclair', 262, 16.0, 24, 6.0),
        createData('Cupcake', 305, 3.7, 67, 4.3),
        createData('Gingerbread', 356, 16.0, 49, 3.9),
      ];
      function getFile(userId,taskId,name,TaskName)
      {
        let data={
            user_id:userId,
            task_id:taskId
        }
        axios.post(environment.BASE_URL+'/get-user-files',data,{responseType: 'arraybuffer'}).then((res)=>{
            // const pdf = new jsPDF();
            // const textLines = pdf.splitTextToSize(res.data, 280); // Adjust width as needed
            // pdf.text(textLines, 10, 20);
            // pdf.save(TaskName+"-"+name+".pdf");
            let blob = new Blob([res.data], {
                type: 'application/pdf'

            })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download =TaskName+"-"+name+".pdf"
            link.click()
            // const fileUrl = res.data.data;

            // // Create an anchor element to trigger the download
            // const link = document.createElement('a');
            // link.href = fileUrl;
            // link.download = 'your-file-name.csv'; // Set the desired filename
            // document.body.appendChild(link);
            // link.click();
            // document.body.removeChild(link);
            
        }).catch((err)=>console.log(err))
        
      }
    function TaskTable(row) {
        console.log(row,"aaaaaaaaaaaaa")
        return(
             
            <TableContainer component={Paper} style={{marginTop:"10px"}} >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell style={{fontWeight:600}} align="center">Learner Name</TableCell>
                <TableCell style={{fontWeight:600}} align="center">Status</TableCell>
                <TableCell style={{fontWeight:600}} align="center">Assignment</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
               
            {row.map((data) => (
                <TableRow
               
                >
                <TableCell align="center" component="th" scope="row">
                    {data.username}
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    {data.status}
                </TableCell>
                <TableCell align="center" component="th" scope="row">{console.log("aaaaaa",data.url_upload)}
                    {data.task_path!=null?
                    <div className='download' onClick={()=>getFile(data.user_id,data.task_id,data.username,data.task_name)} >Click Here to Download</div>
                    :
                        <>
                        {
                            data.url_upload!=null && data.url_upload.length>0?
                            <a className='download' target="_blank" href={data.url_upload} >Click here to Open</a>
                            :
                            <p  style={{color:"grey"}} >No Downloadable</p>
                        }</>
                    }
                   
                </TableCell>
               
                
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
        )
    }
    function handleChangeProgramme(pid){
        let mod=[]
        setSelectedProgrammeId(pid)
        allData.map((val)=>{
            if(val.programme.id==pid)
            {
                setSelectedProgrammeModules(val.modules)
                setSessionList([])
                setModuleList([])
                setTaskList([[]])
                val.modules.map((temp)=>{   
                    console.log(temp.module)
                    mod.push(temp.module)
                })
            }
        })
        setModuleList(mod)
    }
    function handleChangeModule(mid){
        let mod=[]
        selectedProgrammeModules.map((val)=>{
            
            if(val.module.id==mid)
            {
                val.sessions.map((temp)=>{
                    mod.push(temp.session)
                    })
            }
        })
        setSessionList(mod)
        handleChangeSession(mod[0].id)
        setTaskList([[]])
    }
    function handleChangeSession(sId){
        if( sId==0||sId==null)
        {
            return
        }
        let data={
            session_id:sId,
            programme_id:selectedProgrammeId
        }
        setloader(true)
        axios.post(environment.BASE_URL+'/all-trainer-task',data).then((res)=>{
            setTaskList(res.data.data)
            setloader(false)

        }).catch((err)=>console.log(err))

    }
  return (
    <div >
      {
   loader?
   <div className='fullpageloader' style={{marginTop:"-4rem"}} >
      <ThreeCircles
       height="100"
       width="100"
       color="#4fa94d"
       wrapperStyle={{}}
       wrapperClass=""
       visible={true}
       ariaLabel="three-circles-rotating"
       outerCircleColor=""
       innerCircleColor=""
       middleCircleColor=""
     />
   </div>
   :null
}
    <div style={{marginLeft:"3rem"}}>
         
        <div className='programmeList'>
            <label>Select the Programme</label>
            <select onChange={(e)=>handleChangeProgramme(e.target.value)} >
                <option>-- Select a Programme --</option>
                {
                    programmeList.map((programme)=>{
                        return(
                            <option value={programme.id} >{programme.title}</option>
                        )
                    })
                }
            </select>
        </div>
        <div className='programmeList'>
            <label>Select the Module</label>
            <select onChange={(e)=>handleChangeModule(e.target.value)}>
            <option>-- Select a Module --</option>

                {
                    moduleList.map((module)=>{
                        return(
                            <option value={module.id} >{module.title}</option>
                        )
                    })
                }
            </select>
        </div>
        <div className='programmeList'>
            <label>Select the Session</label>
            <select onChange={(e)=>handleChangeSession(e.target.value)}>
            <option>-- Select a Session --</option>
                {
                    sessionList.map((module)=>{
                        return(
                            <option value={module.id} >{module.title}</option>
                        )
                    })
                }
            </select>
        </div>
        <label></label>
        
        { taskList.length>0 && taskList[0].length>0 ? 
        <div style={{marginLeft:"-2rem",width:"70vw"}}  >
          <div className='downloadPdf' onClick={()=>handelDownload()} >
            <img src={myimg} style={{width:"20px",marginRight:"10px"}}/>
            Export
          </div>
        {taskList.map((row) => {
            return(
                <div style={{marginBottom:"3rem"}}>
                    <label style={{fontWeight:700,margin:"10px 10px"}} >{row[0].task_name}</label>     
                   {TaskTable(row)}
                </div>
            )
           
        })}
        
        </div>
        : <div >No Registrants Found</div>}
    </div>
    </div>
  )
}

export default ProgrammeStatus