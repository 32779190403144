import React, { useEffect, useState } from 'react'
import "./Profile.css"
import {useSelector} from 'react-redux'
import {useNavigate } from 'react-router-dom'


function Profile({backPage,navigateBack}) {
    const navigate=useNavigate()
    const user = useSelector(state=>state.user.value)
    const isLoggedIn = useSelector(state=>state.login.value)
    
    console.log(user)
    const [name,setName]=useState(user.name);
    const [emial,setEmail]=useState(user.email);
    const [currPassword,setCurrPassword]=useState('');
    const [newPassword,setNewPassword]=useState('');

    useEffect(()=>{
        if(!isLoggedIn.isLoggedIn)
        navigate('/')
        let localData=localStorage.getItem("LMS_DATA")
        if(localData)
        {
            localData=JSON.parse(localData)
            setName(localData.name)
            setEmail(localData.email)
        }
    },[])

  return (
    <div className='profileContainer'>
        <div className='profileHeader'>
        <img style={{width:'1.5rem'}} onClick={()=>navigateBack()} src={require("../../asserts/left.png")}/>   <label>Your Profile</label>
        </div>
        <div className='profileAlign' >
            <div className='profileInputs'>
                <label>
                    Full Name
                </label>
                <input value={name} disabled/>
            </div>
            <div className='profileInputs'>
                <label>
                    Email Id
                </label>
                <input value={emial} disabled/>
            </div>
        </div>
        <div className='profileAlign' >
            <div className='profileInputs'>
                <label>
                    Current Password
                </label>
                <input onChange={(e)=>setCurrPassword(e.target.value)} type='password'/>
            </div>
            <div className='profileInputs'>
                <label>
                    Reset Password
                </label>
                <input onChange={(e)=>setNewPassword(e.target.value)} type='password'/>
            </div>
        </div>
        <div className='profileUpdateBtn'><button onClick={()=>backPage(emial,currPassword,newPassword)} >Update</button></div>
    </div>
  )
}

export default Profile