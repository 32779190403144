import React,{useEffect} from 'react'
import {useState} from 'react'
import {editDetails} from "../../features/EditUser"
import "./TeacherDashboard.css"
import { Stepper } from 'react-form-stepper';
import { ToastContainer, toast } from 'react-toastify';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SampleFile from "../../Files/account.csv"

import {json, useNavigate } from 'react-router-dom'
import User from '../User/User';
import ProgrammeStatus from "./ProgrammeStatus"
import {useDispatch } from 'react-redux'
import axios from 'axios';
import environment  from '../../environment' ;
import { ThreeCircles } from  'react-loader-spinner'
import Profile from '../Profile/Profile';
import {useSelector} from 'react-redux'
import { FileUploader } from "react-drag-drop-files";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IoTRoboRunner } from 'aws-sdk';


function TeacherDashboard() {

    const navigate = useNavigate();
    const dispatch= useDispatch();
    const isLoggedIn = useSelector(state=>state.login.value)
    const fileTypes = ["PDF"];

    let [navbar,setNavbar]=useState(true)
    let [selectedState,setSelectedState]=useState()
    let [activeStep,setActiveStep]=useState(0)
    let [programmeList,setProgrammeList]=useState([])
    let [programmeName,setProgrammeName]=useState('')
    let [programmeDuration,setProgrammeDuration]=useState('')
    let [programmeDesc,setProgrammeDesc]=useState('')
    let [noOfSessions,setNoOfSessions]=useState(1)
    let [modulesIds,setModulesIds]=useState([])
    let [sessionIds,setSessionIds]=useState([])
    let [allSessionData,setAllSessionData]=useState([])
    let [shownTask,setShownTask]=useState(0)
    let [totalTaskCount,setTotalTaskCount]=useState(0)
    let [allTaskData,setAllTaskData]=useState([])
    let [moduleName,setModuleName]=useState([{id:0,index:0,name:''}])
    let [sessionName,setSessionName]=useState([{ id:0,index:0,name:'',moduleId:0}])
    let [userList,setUserList]=useState({accountCreatedUser:[],registeredUser:[]})
    let [createAccount,setCreateAccount]=useState(false)
    let [createGrpAccount,setGrpCreateAccount]=useState(false)
    let [openPopup,setOpenPopup]=useState(false)
    let [editUser,setEditUser]=useState(false)
    let [createdProgramId,setCreatedProgramId]=useState(null)
    let [createdModules,setCreatedModules]=useState([])
    let [createdSessions,setCreatedSessions]=useState([])
    let [loader,setloader]=useState(false)
    let [taskName,setTaskName]=useState('')
    let [taskDescription,setTaskDescription]=useState('')
    let [taskSessionId,setTaskSessionId]=useState('')
    let [taskfile,setTaskFile]=useState(null)
    let [taskfileName,setTaskFileName]=useState('')
    let [dropDownOpen,setDropDownOpen]=useState(false)
    let [profilePage,setProfilePage]=useState(false)
    let [invRegName,setInvRegName]=useState('')
    let [invRegEmail,serInvRegEmail]=useState('')
    let [invRegProgramme,setinvRegProgramme]=useState('')
    let [uploadGrpFile,setUploadGrpFile]=useState(null)
    let [programmeStatus,setProgrammeStatus]=useState(true)
    let [submitUrl,setSubmitUrl]=useState(true)
    let [role,setrole]=useState('')
    let [userId,setUserId]=useState(0)
    let [sortprogramme,setSortprogramme]=useState({accountCreatedUser:[],registeredUser:[]})
    let [sortRole,setSortRole]=useState('all')
    let [designation,setDesignation]=useState('')
    let [schoolName,setSchoolName]=useState('')
    let [editProgramme,setEditProgramme]=useState(false)
    let [editProgrammeId,setEditProgrammeId]=useState(0)
    let [editIndiTask,setEditIndiTask]=useState(false)
    let [createNewTask,setCreateNewTask]=useState(false)
    let [open, setOpen] = useState(false);
    let [editedTask, setEditedTask] = useState();
    let [deleteProgrammeId,setDeleteProgrammeId]= useState()
    let [deleteTaskId,setDeleteTaskId]= useState()
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
      const accountstyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        width:"45%",
        height:"20%",
        maxWidth:"40rem"

      };
    function getUsers()
    {
      axios.get(environment.BASE_URL+"/get-userList").then((res)=>{
          console.log(res.data.result)
          setUserList(res.data.result)
          setSortprogramme(res.data.result)
      }).catch((err)=>console.log(err))
    }
    function createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
      }
      
   
    function handleProgramSort(pid)
    {
        document.getElementById('setRole').selectedIndex='0';
        if(pid=='all')
        {
            setSortprogramme(userList)
            setSortRole(null)
        }
        else{
            let data={...userList};
            setSortRole(null)
            let accountCreatedUser=data.accountCreatedUser.filter((data)=>data.programme_id==pid)
            let registeredUser=data.registeredUser.filter((data)=>data.programme_id==pid)
            data.accountCreatedUser=accountCreatedUser;
            data.registeredUser=registeredUser;
            setSortprogramme(data)
        }
    }
    function handleRoleSort(rid)
    {
        document.getElementById("setPrograme").selectedIndex='0';
        if(rid=='all')
        {
            setSortprogramme(userList)
        }
        else{
            let data={...userList};
            let accountCreatedUser=data.accountCreatedUser.filter((data)=>data.role_id==rid)
            let registeredUser=data.registeredUser.filter((data)=>data.role_id==rid)
            data.accountCreatedUser=accountCreatedUser;
            data.registeredUser=registeredUser;
            setSortprogramme(data)
        }
    }
    useEffect(()=>{
        if(!isLoggedIn.isLoggedIn)
        navigate("/")
        setloader(true)
        getUsers()
        getLocalData() 
        let data={
            user_id:userId,
            role_id:2
        }

    },[userId])
    function allProgramme()
    {
        axios.get(environment.BASE_URL+'/programme').then((response)=>
        {
          let temp=[...response.data.data];
          temp.map((data)=>{
            data.checked='false'
          })
          setProgrammeList(temp)
          setloader(false)

        }) .catch(error => {
          console.error(error);
        });
    }
    function getTrainerProgramme(id)
    {
        setloader(true)
        let data={
            user_id:id
        }
        axios.post(environment.BASE_URL+'/list-programme',data).then((response)=>
        {
          let temp=[...response.data.data];
          temp.map((data)=>{
            data.checked='false'
          })
          setProgrammeList(temp)
          setloader(false)

        }) .catch(error => {
          console.error(error);
        });
    }
    function getLocalData()
    {
        let role=localStorage.getItem("LMS_ROLE")
        let localData=localStorage.getItem("LMS_DATA")
        if(localData)
        {
            localData=JSON.parse(localData)
            setUserId(localData.userId)
        }
        setrole(role)
        if(role=='Trainer'){
            setSelectedState('Add Module')
        }
        else{
            setSelectedState('Create Account')
            allProgramme()
        }
        setloader(false)
 
    }
    const nextStep = ()=>{

        if(activeStep==0)
        {
            if( !editProgramme && (programmeName.length==0 || programmeDesc.length==0 || programmeDuration.length==0))
            {
                toast.error("Please Enter the required fields")
                return
            }
            setloader(true)
            let data={
                title:programmeName,
                description:programmeDesc,
                duration:programmeDuration,
                user_id:userId,
                url_upload:submitUrl,
                programme_id:editProgrammeId
            }
            axios.post(environment.BASE_URL+'/add-programme',data).then((response)=>
            {
                console.log("Create program",response)
                setCreatedProgramId(response.data.data.id?response.data.data.id:response.data.data[0].id)
                if(editProgramme)
                getModuleDetails(response.data.data[0].id)
                else 
                setloader(false)
                if(activeStep<steps.length-1)
                    setActiveStep(activeStep+1)
            }).catch((error)=>
            {
                setloader(false);
                console.log(error)
                toast.error("Something went wrong. Please try again")
            })
        }
        if(activeStep==1)
        {
            let moduleData=[];
            let moduleIds=[]
            let flag=0
            moduleName.map((data)=>
            {
                if(data.name==0 )
                {
                    flag=1
                }
            })
            if( !editProgramme&& flag==1)
            {
                toast.error("Please Enter the required fields")
                return
            }
            
            moduleName.map((data)=>
            {
                moduleData.push(data.name)
                moduleIds.push(data.id)
            })
            let data;
            if(editProgramme)
            {
                data={
                    title:moduleData,
                    description:'programmeDesc',
                    user_id: userId,
                    status: 1,
                    programme_id:createdProgramId , 
                    module_id:moduleIds     
                }
            }   
            else{         
                data={
                    title:moduleData,
                    description:'programmeDesc',
                    user_id: userId,
                    status: 1,
                    programme_id:createdProgramId                
                }
            }
            
            setloader(true)
            axios.post(environment.BASE_URL+'/add-module',data).then((response)=>
            {
                
                console.log("Create Module",response)
                setCreatedModules(response.data.data)

                if(editProgramme)
                getSessionDetails(response.data.data)
                else
                setloader(false)
                if(activeStep<steps.length-1)
                    setActiveStep(activeStep+1)
            }).catch((error)=>
            {
                setloader(false)
                toast.error("Something went wrong. Please try again")
            })
            
        }
        if(activeStep==2)
        {
            let sessionData=[];
            let moduleId=[];
            let sessionIds=[];
            let flag=0
            sessionName.map((data)=>
            {
                if(data.name==0 || data.moduleId<=0 )
                {
                    flag=1
                }
            })
            if(!editProgramme && flag==1)
            {
                toast.error("Please Enter the required fields")
                return
            }
            setloader(true)
            sessionName.map((data)=>
            {
                sessionData.push(data.name)
                moduleId.push(data.moduleId)
                sessionIds.push(data.id)
            })
            console.log(sessionData)  
            let data;
            if(editProgramme)
            {
                data={
                    title:sessionData,
                    description:'programmeDesc',
                    user_id: userId,
                    status: 1,
                    module_id:moduleId,
                    programme_id:createdProgramId,
                    session_id:sessionIds
                }
            }
            else{
                data={
                    title:sessionData,
                    description:'programmeDesc',
                    user_id: userId,
                    status: 1,
                    module_id:moduleId,
                    programme_id:createdProgramId
                }
            }
            axios.post(environment.BASE_URL+'/add-session',data).then((response)=>
            {
                console.log("Create Module",response)
                setCreatedSessions(response.data.data)
                handleGetAllTasks(createdProgramId)
                if(activeStep<steps.length-1)
                    setActiveStep(activeStep+1)
            }).catch((error)=>
            {
                setloader(false)
                toast.error("Something went wrong. Please try again")
            })
            
        }
        if(activeStep==3)
        {
            handleSubmitNewProgramme(true)
        }
        // if(activeStep<steps.length-1)
        // setActiveStep(activeStep+1)
    }
    const prevStep = ()=>{
        if(activeStep!=0)
            setActiveStep(activeStep-1)
    }
    const steps = [
        { label: 'Programme Setup' },
        { label: 'Modules Setup'  },
        { label: 'Sessions Setup' },
        { label: 'Tasks Setup' },
      ];
    const handleSessionName = (data,index)=>{
        let temp=[...sessionName];
        temp.map((val)=>
        {
            if(val.index==index)
                val.name=data
        })
        setSessionName(temp)
    }
    function goback()
    {
        setProfilePage(false)

    }
    function handleGetAllTasks(programme_id)
    {
        let data={
            programme_id:programme_id
        }
        axios.post(environment.BASE_URL+'/allTasks',data).then((response)=>{
            setAllTaskData(response.data.data)
            setloader(false)
        }).catch((err)=>
        {
            setloader(false)
            toast.error(err.response.data.message);
        })
        
    }
    const handelSessionModuleChange =(data,index)=>{
        let temp=[...sessionName];
        temp.map((val)=>
        {
            if(val.index==index)
                val.moduleId=data
        })
        setSessionName(temp)
    }
    const removeSessionName = (index)=>{
        let temp=[...sessionName];
        temp=temp.filter((val)=>val.index!=index)
        temp.map((val,index)=>
        {
            val.index=index
        })
        if(temp.length==0)
            temp.push({id:0,index:0,name:''})
        setSessionName(temp)
    }
    const handleModuleName = (data,index)=>{
        let temp=[...moduleName];
        temp.map((val)=>
        {
            if(val.index==index)
                val.name=data
        })
        setModuleName(temp)
    }
    const removeModuleName = (index)=>{
        let temp=[...moduleName];
        temp=temp.filter((val)=>val.index!=index)
        temp.map((val,index)=>
        {
            val.index=index
        })
        if(temp.length==0)
            temp.push({id:0,index:0,name:''})
        setModuleName(temp)
    }
    function togglepopup()
    {
        setOpenPopup(!openPopup)
    }

    function handleSubmitNewProgramme(check)
    {
        if(check){
            toast.success("Programme created Successfully")
            setActiveStep(0);
            setProgrammeName('')
            setProgrammeDuration('')
            setProgrammeDesc('')
            setCreateNewTask(false)
            setNoOfSessions(1)
            setModuleName([{id:0,index:0,name:''}])
            setSessionName([{id:0, index:0,name:'',moduleId:0}])
            return;
        }
        setloader(true)
        let data;
        if(editProgramme && !createNewTask)
        {
            if(taskfile.name){
                data={
                    title:taskName,
                    description:taskDescription,
                    session_id:taskSessionId,
                    file:taskfile,
                    user_id:userId,
                    status:1,
                    url_upload:submitUrl,
                    file_name:taskfileName,
                    task_id:editedTask.id
                }   
            }
            else{
                data={
                    title:taskName,
                    description:taskDescription,
                    session_id:taskSessionId,
                    user_id:userId,
                    status:1,
                    url_upload:submitUrl,
                    file_name:taskfileName,
                    task_id:editedTask.id
                    
                }   
            }
        }
        else{
            data={
                title:taskName,
                description:taskDescription,
                session_id:taskSessionId,
                file:taskfile,
                user_id:userId,
                status:1,
                url_upload:submitUrl,
                file_name:taskfileName
            
            }
        }
        if(taskName.length<=0 && taskDescription.length<=0&&taskSessionId<=0)
        {
            toast.error("Please enter the Fields")
            setloader(false)
            return;
        }
        const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };
        axios.post(environment.BASE_URL+'/add-task',data,config).then((response)=>
        {
            console.log("Create Task",response)
            setTaskName('')
            setTaskDescription('')
            setTaskSessionId('')
            setTaskFile(null)
            setTaskFileName('')
            setSubmitUrl(false)
            if(createNewTask)
            handleGetAllTasks(createdProgramId)
            else
            setloader(false)
            setEditIndiTask(false)
            setCreateNewTask(false)


            // var doc=document.getElementById('fileUpload').value = '';

            if(check){
                toast.success("Programme created Successfully")
                setActiveStep(0);
                setProgrammeName('')
                setProgrammeDuration('')
                setProgrammeDesc('')
                setCreateNewTask(false)
                setNoOfSessions(1)
                setModuleName([{id:0,index:0,name:''}])
                setSessionName([{id:0, index:0,name:'',moduleId:0}])
            }

        }).catch((err)=>
        {
            setloader(false)
            toast.error(err.response.data.message);
        })
        
    }
    function handleEdit(data)
    {
        console.log(data,"aaaaaaaxccccc")
        dispatch(editDetails({name:data.name,email:data.email,role:data.role,status:data.status,programme:data.programme_id}))
        setEditUser(true)
    }
    function loaderFun(value)
    {
        setloader(value)
    }
    function uploadBulkRecord()
    {
        if(taskfile!=null)
        {
            const config = {
                headers: {
                  'content-type': 'multipart/form-data',
                },
              };
            let data={
                csvFile:taskfile
            }
            axios.post(environment.BASE_URL+'/add-group-user',data,config).then((response)=>
            {
                setloader(false)
                toast.success("Uploaded Successfully")
                setCreateAccount(false)
                setGrpCreateAccount(false)
                setOpenPopup(false)
            }).catch((err)=>{
                console.log(err)
                toast.error("Something went Wrong.Please try again")
                setloader(false)
            })
        }
        else{
            toast.error("Please select the file")
        }
    }
    function createIndividualAccoutn()
    {
        if(invRegEmail.length>0 && invRegName.length>0 && invRegProgramme.length>0 && designation.length>0 && schoolName.length>0)
        {
            let data={
                name:invRegName,
                email:invRegEmail,
                programme_id:invRegProgramme,
                school_name:schoolName,
                designation:designation
            }
            setloader(true)
            axios.post(environment.BASE_URL+'/add-individual-user',data).then((response)=>
            {
                setloader(false)
                toast.success("Created Successfully")
                setCreateAccount(false)
                setGrpCreateAccount(false)
                setOpenPopup(false)
                getUsers()
                
            }).catch((err)=>{
                console.log(err)
                setloader(false)
            })
        }
        else
        toast.error("Please fill the details")

        
    }
    function children()
    {
        return (
            <div className='innerInputDiv' >
                {taskfile == null ? <>
                            <img src={require("../../asserts/taskUpload.png")} style={{}} />
                            <p>Drag and drop your file here</p>
                            <div className='chooseYourFile' >
                            {/* <input  className='hideInput'  id='fileUpload'  onChange={(e)=>{setTaskFile(e.target.files[0]);}}  type='file'/> */}
                                <div className='align' style={{borderRadius:"1px",background:"rgba(207, 198, 183, 0.85)"}} >Choose your File</div>
                            </div>
                            </>
                :
                <div className='uplodedFile'> <img src={require("../../asserts/taskUpload.png")} style={{marginRight:"20px"}} />{
                   taskfileName?taskfileName:taskfile.name}</div>
                }
            </div>
        )
    }
    function handleProgramStatus()
    {
        setProgrammeStatus(!programmeStatus)
    }
    function navigateBack()
    {
        setEditUser(false)
    }
    function handelProfilepage()
    {
       
        setDropDownOpen(false)
         setProfilePage(true)
    }
    function handlePogrammeEdit(id)
    {
        let data={
            programme_id:id
        }
        setloader(true)
        axios.post(environment.BASE_URL+'/get-programme-details',data).then((response)=>
        {
            var programData=response.data.data[0]
            setProgrammeName(programData.title)
            setProgrammeDuration(programData.duration)
            setProgrammeDesc(programData.description)
            setEditProgrammeId(programData.id)
            setEditProgramme(true)
            setloader(false)
        }).catch((error)=>{
            setloader(false)
            toast.error("Something went Wrong.Please try again")

        })
    }
    function getModuleDetails(id)
    {
        let data={
            programme_id:id
        }
        axios.post(environment.BASE_URL+'/get-module-details',data).then((response)=>
        {
            var programData=response.data.data
            let modData=[];
            let modId=[]
            let i=0
            programData.map((data,index)=>{
                let temp={
                    id:data.id,
                    index:index,
                    name:data.title
                }
                i+=1;
                modId.push(data.id)
                modData.push(temp)
            })
            // modData.push({index:i,name:''})
            setModuleName(modData)
            setModulesIds(modId)
            setloader(false)
        }).catch((error)=>{
            setloader(false)
            toast.error("Something went Wrong.Please try again")

        })
    }
    function getSessionDetails(modData,changeModule=false)
    {
        let ids;
        if(!changeModule){
            ids=[];
            modData.map((res)=>{
                ids.push(res.id)
            })
        }
        else{
            ids=[modData]
        }
        let data={
            module_id:ids
        }
        axios.post(environment.BASE_URL+'/get-session-details',data).then((response)=>
        {
            var sessionData=response.data.data
            console.log(sessionData)
            let sesData=[];
            let sesId=[]
            sessionData.map((data,index)=>{
                let temp={
                    id:data.id,
                    index:index,
                    name:data.title,
                    moduleId:data.module_id
                }
                sesId.push(data.id)
                sesData.push(temp)
            })
            setAllSessionData(sessionData)
            setSessionName(sesData)
            setSessionIds(sesId)
            setloader(false)
        }).catch((error)=>{
            setloader(false)
            toast.error("Something went Wrong.Please try again")
        })
    }

    function getTaskDetails(session_id)
    {
        let data={
            session_id:session_id
        }
        setloader(true)
        axios.post(environment.BASE_URL+'/get-task-details',data).then((response)=>
        {
            let data=response.data.data
            setAllTaskData(data)
            console.log(data)
            setTotalTaskCount(data.length)
            setTaskName(data[shownTask].title)
            setTaskDescription(data[shownTask].description)
            setTaskSessionId(data[shownTask].session_id)
            setTaskFile(data[shownTask].path)
            setTaskFileName(data[shownTask].file_name)
            setloader(false)

        }).catch((error)=>{
            setloader(false)
            toast.error("Something went Wrong.Please try again")
        })

    }
    function handleChangeTask(status)
    {

    }
    function createIndiAccount(userData){
        setCreateAccount(true);
        setGrpCreateAccount(false);
        setInvRegName(userData.name)
        let data=userData.programme_id.replace(/^\D+/g, '')
        setinvRegProgramme(data.replace(/^\D+/g, ''))
        serInvRegEmail(userData.email)
        setSchoolName(userData.school_name)
        setDesignation(userData.designation)
    }
    function closeProfilePage(email,currPassword,newPassword)
    {
        setloader(true)
        console.log(currPassword.length,newPassword.length)
        if(currPassword.length==0 || newPassword.length==0)
        {
            toast.warn("Please enter the Fields")
            setloader(false)
            return
        }
        let data={
            email:email,
            current_password:currPassword,
            new_password:newPassword
        }
        axios.post(environment.BASE_URL+'/reset-password',data).then((res)=>{
            setloader(false)
            toast.success("Profile Updated SuccessFully")
            setProfilePage(false)
        }).catch((err)=>{toast.error(err.response.data.message);  setloader(false)
    })     
    }
    function taskCreation()
    {
        return(
            <>
             <div className='taskConatainer'>
                        <div style={{display:"flex",flexDirection:"column",width:"60%"}}>
                            <label>Task Name</label>
                            <input className='taskInput'  style={{margin:"0"}} type='text' value={taskName} onChange={(e)=>setTaskName(e.target.value)}/>
                        </div>
                        <div style={{display:"flex",flexDirection:"column"}}>
                            <label>Select the Respective Session</label>
                            <select className='moduleOptionss' style={{width:"100%"}} value={taskSessionId}  onChange={(e)=>setTaskSessionId(e.target.value)} >
                                <option>--- Select the Session ----</option>
                                {
                                    createdSessions.map((session)=>
                                    {                                        
                                        return(
                                            <>
                                            <option disabled> {session[0].title}</option>
                                                {
                                                    session[1].map((data)=>{
                                                        return(
                                                            <option value={data.id}>{data.title}</option>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                    })
                                }
                            </select>
                        </div>
                   </div>
                   <div  className='taskDesc' style={{display:"flex",flexDirection:"column"}}>
                   <label>Description</label>
                     <textarea onChange={(e)=>setTaskDescription(e.target.value)} value={taskDescription} ></textarea>
                    </div>
                    <div  className='taskDesc' style={{display:"flex",flexDirection:"column",minHeight:"5rem"}}>
                   <label style={{paddingBottom:"10px"}}>Submit as Url </label>
                   <div>
                    {console.log(submitUrl)}
                    {submitUrl==false?
                        <>
                            <label className='radio' >No </label><input checked onClick={()=>{setSubmitUrl(false); }}  style={{minWidth:"1rem",maxWidth:"1rem",marginRight:"20px"}} type='radio'  name="urlSubmit"/> 
                            <label className='radio'>Yes </label><input  onClick={()=>{setSubmitUrl(true);}}style={{minWidth:"1rem",maxWidth:"1rem"}} type='radio'  name="urlSubmit"/>
                        </>
                    :
                        <>
                            <label className='radio' >No </label><input  onClick={()=>{setSubmitUrl(false); }}  style={{minWidth:"1rem",maxWidth:"1rem",marginRight:"20px"}} type='radio'  name="urlSubmit"/> 
                            <label className='radio'>Yes </label><input checked  onClick={()=>{setSubmitUrl(true);}}style={{minWidth:"1rem",maxWidth:"1rem"}} type='radio'  name="urlSubmit"/>
                        </>

                    }
                    
                   </div>
                    </div>
                    <div  className='taskDesc' style={{display:"flex",flexDirection:"column"}}>
                   <label>Tasks file (if required)</label>
                     <div className='inputDiv'>
                     <FileUploader
                        multiple={true}
                        name="file"
                        classes="fileUploder"
                        types={fileTypes}
                        onDrop={(file)=>{setTaskFile(file[0]);setTaskFileName(file[0].name)}}
                        onSelect={(file)=>{setTaskFile(file[0]);setTaskFileName(file[0].name)}}
                        children={children()}
                    />
                    
                          {taskfile != null ? 
                        <div style={{padding:"10px",textAlign:"center"}}><img onClick={()=>{setTaskFile(null)}} style={{width:"15px",height:"15px",cursor:"pointer"}} src={require("../../asserts/close.png")}/></div>
                            :
                            <></>
                        }
                </div>
                    </div>
            </>
        )
    }
    function backBtn()
    {
        setActiveStep(0);
        setProgrammeName('')
        setProgrammeDuration('')
        setProgrammeDesc('')
        setCreateNewTask(false)
        setNoOfSessions(1)
        setModuleName([{id:0,index:0,name:''}])
        setSessionName([{id:0, index:0,name:'',moduleId:0}])
    }
    function getSectionComponent() {
        switch(activeStep) {
          case 0: 
          return (
            <div className='moduleContainer' >
                <div style={{display:"flex",width:'90%',justifyContent:"space-between"}}>
                    <div style={{display:"flex",flexDirection:"column",width: '50%'}} >
                        <label>Name of the Programme</label>
                        <input type='text' placeholder='Art of Speaking' value={programmeName} onChange={(e)=>setProgrammeName(e.target.value)} />
                    </div>
                    <div style={{display:"flex",flexDirection:"column",width: '40%'}}>
                    <label style={{display:"flex",alignItems:"center"}}>Duration  <p style={{fontSize:"0.6em",marginLeft:"10px"}}> (In Weeks)</p></label>
                        <input type='number' placeholder='3 ' min={1} value={programmeDuration} onChange={(e)=>setProgrammeDuration(e.target.value)} />
                    </div>
                </div>
               <label>Description</label>
                <textarea onChange={(e)=>setProgrammeDesc(e.target.value)} >{programmeDesc}</textarea>
            </div>
            )
          case 1: 
          return(
            <div className='moduleContainer' style={{justifyContent:"flex-start",marginTop:"1rem",marginBottom:"1rem",marginRight:"3rem"}}>
                   <label> Add Module </label>
                {
                    moduleName.map((session ,index)=>
                    {
                        return(
                            <div className='indiSession'>
                                <div className='index'>{index+1}</div>
                                <div className='sesName'>
                                    <input type='text' className='sessionNameIndi' onChange={(e)=>handleModuleName(e.target.value,session.index)} value={session.name} />
                                </div>
                                <div className='addContainer' style={{marginLeft:"20px"}} onClick={()=>removeModuleName(session.index)} ><img className='removebutton' src={require('../../asserts/close.png')}/> </div>
                                </div>
                        )
                    })
                }
                  <div className='addContainer glass' onClick={()=>setModuleName([...moduleName,{id:0,index:moduleName.length,name:''}])}  ><img className='addbutton' src={require('../../asserts/close.png')}/> </div>
            </div>
        )
          case 2:
            return(
                <div className='moduleContainer' style={{justifyContent:"flex-start",marginTop:"1rem",marginBottom:"1rem",marginRight:"2rem"}}>
                       <label> Add Session </label>
                    {
                        sessionName.map((session ,index)=>
                        {
                            return(
                                <div className='indiSession'>
                                    <div className='index'>{index+1}</div>
                                    <div className='sesName' style={{marginRight:"1.3rem"}}>
                                        <input type='text' style={{flex:1,marginTop:0}} className='sessionNameIndi imp' onChange={(e)=>handleSessionName(e.target.value,session.index)} value={session.name} />
                                    </div>
                                    <div style={{width:"40%"}}>
                                        <select className='moduleOptionss' style={{width:"100%"}} onChange={(e)=>handelSessionModuleChange(e.target.value,session.index)} value={session.moduleId} >
                                            <option>--- Select the Module ----</option>
                                            {
                                                createdModules.map((modules)=>
                                                {
                                                    return(
                                                        <option value={modules.id}>{modules.title}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='addContainer'  onClick={()=>removeSessionName(session.index)} ><img className='removebutton' src={require('../../asserts/close.png')}/> </div>
                                    </div>
                            )
                        })
                    }
                      <div className='addContainer glass' onClick={()=> setSessionName([...sessionName,{id:0,index:sessionName.length,name:'',moduleId:0}])}  ><img className='addbutton' src={require('../../asserts/close.png')}/> </div>
                </div>
            )
          case 3:
            return(
                <div className='moduleContainer' style={{minHeight:"100%",maxHeight:"100vh",overflowY:"inherit",display:'flex',flexDirection:"column"}} >
                    {deletePopUp('Are you Sure you want to Delete this Task?','task')}

                    {
                    editProgramme && !editIndiTask?
                    // FOR EDIT PROGRAMME AND IT SHOWS THE LIST OF TASKS IN A TABLE BASED ON MODULES
                        <>
                         <div className='downloadPdf' style={{alignSelf:"end",display:"flex",alignItems:"center",width:"9rem"}} onClick={()=>{setEditIndiTask(true);setCreateNewTask(true)}} >
                            <img src={require('../../asserts/add.png')} style={{width:"20px",marginRight:"10px"}}/>
                            Add new Task
                        </div>
                        <div style={{width:"95%",paddingRight:"1rem",marginTop:"1rem",overflow:"auto"}} >
                        {
                            allTaskData.map((data)=>{
                                
                                return(
                                    <>
                                    <label>{data.module.title}</label>
                                <TableContainer component={Paper} style={{minHeight:"10rem",margin:"1rem 0"}} >
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                        <TableCell align="center">Session Title</TableCell>
                                        <TableCell align="center">Task Title</TableCell>
                                        <TableCell align="center" style={{maxWidth:"4rem",width:"4rem"}}>Modify</TableCell>
                                       
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {data.sessions.map((sesData) => 
                                            {
                                            return(
                                            
                                            sesData.tasks.map((row)=> {
                                                return(
                                                <TableRow
                                                    key={row.tasks.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                
                                                    <TableCell align="center">{row.tasks.title}</TableCell>
                                                    <TableCell align="center">{row.tasks.title}</TableCell>
                                                    <TableCell align='center' style={{display:'flex',justifyContent:"space-between",maxWidth:'4rem'}} ><img style={{width:"20px",cursor:'pointer'}} onClick={()=>getIndiTaskDetails(row.tasks.id)} src={require("../../asserts/edit.png")}/> |
                                                     <img  style={{width:"20px",cursor:'pointer'}} onClick={()=>{handleOpen();setDeleteTaskId(row.tasks.id)}} src={require("../../asserts/delete.png")}/></TableCell>
                                            
                                                </TableRow>
                                                )})
                                            )
                                        }
                                        )} 
                                    </TableBody>
                                    </Table>
                                </TableContainer>
                                </>)
                                
                            })
                        }
                        </div>
                        </>
                    
                        :
                        //FOR EDIT PROGRAMME AND IF USER CLICKS IN EDIT TASK IT OPENS TASK WITH PREFILLED DETAILS
                        <>{taskCreation()}</>
                    }
                </div>
                )
          default: return null;
        }
      }
      function handleDeleteTask(id)
      {

      }
      function deletePopUp(message,from)
      {
        return(
            <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
            backdrop: {
                timeout: 500,
            },
            }}
        >
            <Fade in={open}>
            <Box sx={style}>
                <Typography id="transition-modal-title" variant="h6" component="h6" style={{fontSize:"18px",textAlign:'center'}}>
                {message}
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                    <div style={{display:'flex',justifyContent:"space-around"}}>
                        <div className='deleteConfirm' onClick={()=>handelDelete(from)}>YES</div>
                        <div className='deleteConfirm' onClick={()=>handleClose()} >NO</div>
                    </div>
                </Typography>
            </Box>
            </Fade>
        </Modal>
        )
      }
      function handelDelete(from)
      {
        if(from=='programme')
        {
            setloader(true)
            let data={
                programme_id:deleteProgrammeId
            }
            axios.post(environment.BASE_URL+'/delete-programme',data).then((res)=>{   
                getTrainerProgramme(userId)
                setloader(false)
                handleClose()
                toast.success('Programme Deleted Successfully!')
            }).catch((err)=>{toast.error(err.response.data.message);
                setloader(false)      
            })
        }
        else{
            setloader(true)
            let data={
                task_id:deleteTaskId
            }
            axios.post(environment.BASE_URL+'/delete-task',data).then((res)=>{  
                handleGetAllTasks(createdProgramId) 
                handleClose()
                toast.success('Task Deleted Successfully!')
            }).catch((err)=>{toast.error(err.response.data.message);
                setloader(false)      
            })
        }
      }
      function getIndiTaskDetails(task_id)
      {
        setloader(true)
        let data={
            task_id:task_id
        }
        axios.post(environment.BASE_URL+'/get-individual-task',data).then((res)=>{
            setEditIndiTask(true)
            setloader(false)
            let data=res.data.data[0];
            setEditedTask(data)
            setTaskName(data.title)
            setTaskDescription(data.description)
            setTaskSessionId(data.session_id)
            setTaskFile(data.path)
            setSubmitUrl(data.url_upload=='true'?true:false)
            setTaskFileName(data.file_name)
        }).catch((err)=>{toast.error(err.response.data.message);
            setloader(false)      
        })
    }
      function handleLogout()
      {
        localStorage.removeItem("LMS_DATA")
        localStorage.removeItem("LMS_ROLE")
        navigate("/")
      }
  return (
    <div style={{display:"flex",flexDirection:"column"}} >
    {
   loader?
   <div className='fullpageloader'>
      <ThreeCircles
       height="100"
       width="100"
       color="#4fa94d"
       wrapperStyle={{}}
       wrapperClass=""
       visible={true}
       ariaLabel="three-circles-rotating"
       outerCircleColor=""
       innerCircleColor=""
       middleCircleColor=""
     />
   </div>
   :null
}
   <div className='dashboardHeader'>
   <div className='logo removed'>
           <img src={require('../../asserts/logo.webp')} className='logoimg'/> 
           </div>
       <img src={require("../../asserts/profile.png")} style={{width:'2rem'}} onClick={()=>{setDropDownOpen(!dropDownOpen)}}/>
   </div>
   {  
   dropDownOpen?
   <div className='profileBar'>
       <div  onClick={()=>{handelProfilepage()}} > <img src={require("../../asserts/profile.png")} style={{width:'1.2rem',marginRight:"10px"}}/> My Profile</div>
       <div  onClick={()=>{handleLogout()}}> <img src={require("../../asserts/logout.png")} style={{width:'1.2rem',marginRight:"10px"}}/>Logout</div>
   </div>:null
   }
    <div className='containerr' >
                
         <ToastContainer autoClose={2000} closeOnClick={true} pauseOnHover={false} />
        { navbar ?
       <div className='navContainer' >
            {/* <div className='closeBar'>
                <img src={require("../../asserts/close.png")} style={{ width:"20px",opacity:0.5}} onClick={()=>setNavbar(false)}/>
            </div>
            <div className='logo removed'>
                    <img src={require('../../asserts/logo.webp')} className='logoimg'/>        
            </div> */}
            <div className='chatContainer' style={{marginTop:"0rem"}}>
            <img src={require("../../asserts/close-white.png")} style={{ width:"35px",cursor:"pointer",opacity:0.5,alignSelf:"flex-end"}} onClick={()=>setNavbar(false)}/>
                { role =="Trainer"?
                <>
                <div className={selectedState=='Add Module' ? 'selectedModule individualNotes modules' :'individualNotes modules'}onClick={()=> {backBtn(); setEditProgramme(false);setSelectedState('Add Module');setProfilePage(false)}}> 
                    { selectedState=='Add Module' ?
                    <img src={require("../../asserts/addProgram-black.png")} style={{width:"18px",marginRight:"10px"}}/>
                       
                    :
                    <img src={require("../../asserts/addProgram-white.png")} style={{width:"18px",marginRight:"10px"}}/>
                    }
                    Add Programme 
                </div>
                <div className={selectedState=='Your Modules' ? 'selectedModule individualNotes modules' :'individualNotes modules'} onClick={()=>{backBtn(); setEditProgramme(false);setSelectedState('Your Modules');setProfilePage(false)}} >
                     { selectedState=='Your Modules' ?
                     <img src={require("../../asserts/yourProgram-black.png")} style={{width:"18px",marginRight:"10px"}}/> 
                     :
                     <img src={require("../../asserts/yourProgramm-white.png")} style={{width:"18px",marginRight:"10px"}}/> 
                        }
                     Programme Status
                </div>
                <div className={selectedState=='Your Programme' ? 'selectedModule individualNotes modules' :'individualNotes modules'} onClick={()=>{backBtn(); setEditProgramme(false); setSelectedState('Your Programme');getTrainerProgramme(userId);setProfilePage(false)}} >
                     { selectedState=='Your Programme' ?
                     <img src={require("../../asserts/yourProgram-black.png")} style={{width:"18px",marginRight:"10px"}}/> 
                     :
                     <img src={require("../../asserts/yourProgramm-white.png")} style={{width:"18px",marginRight:"10px"}}/> 
                        }
                     Your Programme 
                </div>
                </>
                :
                <div className={selectedState=='Create Account' ? 'selectedModule individualNotes modules' :'individualNotes modules'} onClick={()=> {setSelectedState('Create Account');setProfilePage(false)}} >
                      { selectedState=='Create Account' ?
                     <img src={require("../../asserts/usrList-black.png")} style={{width:"18px",marginRight:"10px"}}/> 
                     :
                     <img src={require("../../asserts/usrList-white.png")} style={{width:"18px",marginRight:"10px"}}/> 
                        }
                     Users List
                      </div>
                }
                </div>
        </div>
        :
        <div className='hiddenNav'>
            <img src={require("../../asserts/menu.png")} style={{width:"40px",margin:'20px 0px'}} onClick={()=>setNavbar(true)}/>
        </div>
        }
        <div className='displayContainer'>
        {/* <div className='dashboardHeader'>
                <img src={require("../../asserts/profile.png")} style={{width:'2rem'}} onClick={()=>setDropDownOpen(!dropDownOpen)}/>
            </div>
        {  
            dropDownOpen?
            <div className='profileBar'>
                <div onClick={()=>handelProfilepage()} > <img src={require("../../asserts/profile.png")} style={{width:'1.2rem',marginRight:"10px"}}/ > My Profile</div>
                <div  onClick={()=>handleLogout()}  > <img src={require("../../asserts/logout.png")} style={{width:'1.2rem',marginRight:"10px"}}/>Logout</div>
            </div>:null
            } */}
        { profilePage ?<Profile backPage={closeProfilePage} navigateBack={goback} />
        :
        <>
        { !editUser?
            < >
           {
            selectedState =='Add Module'?
                <div className='module' onClick={()=>setDropDownOpen(false)}>
                    <Stepper
                    style={{width:'100%',padding:0}}
                    steps={steps}
                    activeStep={activeStep}
                    styleConfig={{
                        activeBgColor: '#F8D662',
                        completedBgColor: '#F8D66290',
                        inactiveBgColor: '#eee',
                        activeTextColor: 'black',
                        completedTextColor: '#355239',
                        inactiveTextColor: '#CFC6B7'
                      }}
                    />
                    <div className='stepBody'>
                        { getSectionComponent()  }
                    </div>
                      { activeStep>=3 && !editProgramme?
                      <div className='loginSubmittt' style={{display:"flex",justifyContent:"flex-end",width:"90%", marginTop:"2rem"}}>
                            <button className='btn' style={{ cursor:"pointer", marginRight:"3rem",fontWeight:"500",maxWidth:"15rem",color:"#1A291D"}} onClick={()=>handleSubmitNewProgramme(false)}>Submit & create Another</button>
                        </div>
                        :
                        <>{activeStep>=3 && editProgramme && !createNewTask?
                        <div className='loginSubmittt' style={{display:"flex",justifyContent:"flex-end",width:"90%", marginTop:"2rem"}}>
                            <button className='btn' style={{ cursor:"pointer", marginRight:"3rem",fontWeight:"500",maxWidth:"15rem",color:"#1A291D"}} onClick={()=>handleSubmitNewProgramme(false)}>Update</button>
                        </div>
                        :
                        <>
                            {
                                activeStep>=3 && editProgramme && createNewTask?
                                <div className='loginSubmittt' style={{display:"flex",justifyContent:"flex-end",width:"90%", marginTop:"2rem"}}>
                                    <button className='btn' style={{ cursor:"pointer", marginRight:"3rem",fontWeight:"500",maxWidth:"15rem",color:"#1A291D"}} onClick={()=>handleSubmitNewProgramme(false)}>Create</button>
                                </div>
                                :
                                null
                            }
                        </>
                        }</>
                    }
                    <div className='navigation' style={{width:"80%"}}>
                    { activeStep>1?
                        <div className='nav-btn' style={{border:"1px solid #355239",color:"#355239"}} onClick={()=>prevStep()} >Previous</div>
                        :<div></div>
                    }
                    { activeStep<3?
                    <div className='nav-btn' style={{border: "1px solid #355239",color:"white",background: "#355239"}} onClick={()=>nextStep()}>Next</div>
                    :
                    <div className='nav-btn' style={{border: "1px solid #355239",color:"white",background: "#355239"}} onClick={()=>nextStep()}>Next</div>

                        // <div className='loginSubmit' style={{display:"flex",maxWidth:"none",width:"60%",justifyContent:"space-between"}}>
                        //     <button className='btn' style={{marginRight:"3rem",maxWidth:"15rem",background:'#ACFF88',color:"black"}} onClick={()=>handleSubmitNewProgramme(false)}>Submit & Create Another</button>
                        //     <button style={{marginRight:"3rem",maxWidth:"10rem"}}  className='btn' onClick={()=>handleSubmitNewProgramme(true)}>Complete</button>
                        // </div>
                    }
                </div>

                </div>
            :
            
            <>
            {
                selectedState =='Your Programme'?
                <>
                {!editProgramme?
                    <div style={{minHeight:"90%",minWidth:"90%",marginTop:"1rem"}}>
                    {/* <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                        }}
                    >
                        <Fade in={open}>
                        <Box sx={style}>
                            <Typography id="transition-modal-title" variant="h6" component="h6" style={{fontSize:"18px",textAlign:'center'}}>
                            Are you Sure  you want  to Delete this Programme?
                            </Typography>
                            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                <div style={{display:'flex',justifyContent:"space-around"}}>
                                    <div className='deleteConfirm' onClick={()=>setEditProgramme(true)}>YES</div>
                                    <div className='deleteConfirm' onClick={()=>handleClose()} >NO</div>
                                </div>
                            </Typography>
                        </Box>
                        </Fade>
                    </Modal> */}
                    {deletePopUp('Are you Sure you want to Delete this Programme?','programme')}
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: '85vh' }}>
                        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight:600}} >Programme Name</TableCell>
                                <TableCell style={{fontWeight:600}} align='center' >Modify</TableCell>

                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {programmeList.map((row) => (
                                    <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <TableCell style={{minWidth:"25rem"}}  scope="row">
                                        {row.title}
                                    </TableCell>
                                    <TableCell style={{display:'flex',justifyContent:"space-between"}} ><img style={{width:"20px",cursor:'pointer'}} onClick={()=>handlePogrammeEdit(row.id)} src={require("../../asserts/edit.png")}/>
                                     | <img  style={{width:"20px",cursor:'pointer'}} onClick={()=>{handleOpen();setDeleteProgrammeId(row.id)}} src={require("../../asserts/delete.png")}/></TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </Paper>

                </div>
                :
                <div style={{minHeight:"100%",minWidth:"100%"}}>
                    <img className='backBtn' src={require('../../asserts/leftarrow.png')} onClick={()=>{backBtn();setEditProgramme(false);setActiveStep(0)}} />
                    <div className='module' onClick={()=>setDropDownOpen(false)}>
                    <Stepper
                    style={{width:'100%',padding:0}}
                    steps={steps}
                    activeStep={activeStep}
                    styleConfig={{
                        activeBgColor: '#F8D662',
                        completedBgColor: '#F8D66290',
                        inactiveBgColor: '#eee',
                        activeTextColor: 'black',
                        completedTextColor: '#355239',
                        inactiveTextColor: '#CFC6B7'
                      }}
                    />
                    <div className='stepBody'>
                        { getSectionComponent()  }
                    </div>
                      { activeStep>=3 && !editProgramme?
                      <div className='loginSubmittt' style={{display:"flex",justifyContent:"flex-end",width:"90%", marginTop:"2rem"}}>
                            <button className='btn' style={{ cursor:"pointer", marginRight:"3rem",fontWeight:"500",maxWidth:"15rem",color:"#1A291D"}} onClick={()=>handleSubmitNewProgramme(false)}>Submit & create Another</button>
                        </div>
                        :
                        <>
                       </>
                    }
                    <div className='navigation' style={{width:"80%"}}>
                    { 
                        !editProgramme?
                        <>
                            {
                                activeStep>1?
                                <div className='nav-btn' style={{border:"1px solid #355239",color:"#355239"}} onClick={()=>prevStep()} >Previous</div>
                                :<div></div>
                            }
                        </>
                        :
                        <>
                            {
                                activeStep>1 && activeStep<=3 && !editIndiTask?
                                <div className='nav-btn' style={{border:"1px solid #355239",color:"#355239"}} onClick={()=>prevStep()} >Previous</div>
                            :
                            <div></div> 
                            }
                        </>
                    }
                    { activeStep<=3 && !editProgramme?
                        <div className='nav-btn' style={{border: "1px solid #355239",color:"white",background: "#355239"}} onClick={()=>nextStep()}>Next</div>
                        :
                        <>
                        {activeStep==3 && editProgramme && editIndiTask?
                              <>{
                                createNewTask?
                                <div style={{display:"flex"}}>
                                    <div className='nav-btn' style={{border: "1px solid #355239",color:"#355239",marginRight:"1rem"}} onClick={()=>{setEditIndiTask(false);setCreateNewTask(false)}}>Close</div>
                                    <div className='nav-btn' style={{border: "1px solid #355239",color:"white",background: "#355239"}} onClick={()=>handleSubmitNewProgramme(false)}>Create</div>
                                </div>
                                :
                                    <div className='nav-btn' style={{border: "1px solid #355239",color:"white",background: "#355239"}} onClick={()=>handleSubmitNewProgramme(false)}>Update</div>
                              } </> 
                        :
                        <>
                        { 
                        activeStep==3 && editProgramme ?

                            <></>
                        :
                            <div className='nav-btn' style={{border: "1px solid #355239",color:"white",background: "#355239"}} onClick={()=>nextStep()}>Next</div>
                        
                        }</>
                        
                        }</>
                    }
                </div>

                </div>
                </div>

                }
                    
                </>
                :
                <div style={{minHeight:"100%",minWidth:"100%",marginTop:"4rem"}}>    
                {  
                    selectedState =='Your Modules'?
                    <div className='module' style={{marginTop:0}} >
                        { !programmeStatus?
                            <div>
                                <button onClick={()=>handleProgramStatus()} >Programme Status</button>
                            </div>
                        :
                            <div style={{height:"100%",width:"100%"}}>
                                <ProgrammeStatus loaderFun={loaderFun}/>
                            </div>
                        }
                    </div>
                    :
                    <div className='createAccount' style={{marginTop:"-4rem"}}>
                        { !createAccount
                        ?
                        <div>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={openPopup}
                        onClose={togglepopup}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                        }}
                    >
                        <Fade in={openPopup}>
                        <Box sx={accountstyle}>
                        <div className='popupConatiner'>
                            <div>
                                <label>Select the type of Registration</label>
                            </div>
                           <div className='popupBtn' style={{marginTop:"2rem"}}>
                                <div className='loginSubmit' style={{alignSelf: 'center', padding: '1rem 0',width:"40%"}}>
                                    <button onClick={()=>{setCreateAccount(true);setGrpCreateAccount(false)}}>Individual Registration</button>
                                </div>
                                <div className='loginSubmit' style={{alignSelf: 'center', padding: '1rem 0',width:"40%"}}>
                                    <button onClick={()=>{setGrpCreateAccount(true);setCreateAccount(true)}}>Group Registration</button>
                                </div>
                                
                            </div>
                        </div>
                        </Box>
                        </Fade>
                    </Modal>
                        {/* <Modal
                            open={openPopup}
                            onClose={togglepopup}
                            className="mymodal"
                            overlayClassName="myoverlay"
                            closeTimeoutMS={200}
                            contentLabel="My dialog"
                        >
                        <div className='popupConatiner'>
                            <div>
                                <label>Select the type of Registration</label>
                            </div>
                           <div className='popupBtn'>
                                <div className='loginSubmit' style={{alignSelf: 'center', padding: '1rem 0',width:"40%"}}>
                                    <button onClick={()=>{setCreateAccount(true);setGrpCreateAccount(false)}}>Individual Registration</button>
                                </div>
                                <div className='loginSubmit' style={{alignSelf: 'center', padding: '1rem 0',width:"40%"}}>
                                    <button onClick={()=>{setGrpCreateAccount(true);setCreateAccount(true)}}>Group Registration</button>
                                </div>
                                
                            </div>
                        </div>
                    </Modal> */}
                                <div className='accountHeader'>
                                    <label>List of Users</label>
                                    <div onClick={()=>togglepopup()} > <button>Create Account</button> </div>
                                </div>
                                <div className='accountBody'>
                                <div className='sort'>
                                    <div style={{display:"flex",flexDirection:"column"}} >
                                        <label style={{marginBottom:"20px"}}>Select the Programme</label>
                                        <select style={{maxWidth:"15rem",marginBottom:"20px"}} id="setPrograme" onChange={(e)=>handleProgramSort(e.target.value)} >
                                        <option value='all' >---Select the Programme----</option>

                                            {
                                                programmeList.map((programme)=>{
                                                    return(
                                                    <option value={programme.id} >{programme.title}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div style={{display:"flex",flexDirection:"column"}} >
                                        <label style={{marginBottom:"20px"}}>Select the Role</label>
                                        <select style={{width:"11rem",marginBottom:"20px"}} id="setRole" onChange={(e)=>handleRoleSort(e.target.value)} >
                                            <option value="all" >---Select the Role---</option>
                                            <option value="3">Learner</option>
                                            <option value="2">Trainer</option>
                                        </select>
                                    </div>
                                </div>
                                <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                    <TableRow >
                                        <TableCell style={{fontWeight:"600"}}>Name</TableCell>
                                        <TableCell align="left" style={{fontWeight:"600"}}>Email</TableCell>
                                        <TableCell align="left" style={{fontWeight:"600"}}>Role</TableCell>
                                        <TableCell align="left" style={{fontWeight:"600"}}></TableCell>
                            
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {sortprogramme.registeredUser.map((row) => (
                                        <TableRow
                                        key={row.id}
                                        >
                                        <TableCell  >
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="left">{row.email}</TableCell>
                                        <TableCell align="left">{row.role_id ==2 ?
                                            "Trainer":"Learner"
                                        }</TableCell> 
                                        <TableCell align="right"> <Tooltip title="Create Account" placement="top" onClick={()=>createIndiAccount(row)} >
                                                <div className='download' >Create Account</div>
                                        </Tooltip></TableCell>
                                    
                                        </TableRow>
                                    ))}
                                    {sortprogramme.accountCreatedUser.map((row) => (
                                        <TableRow
                                        key={row.id}
                                        >
                                        <TableCell  >
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="left">{row.email}</TableCell>
                                        <TableCell align="left">{row.role_id ==2 ?
                                            "Trainer":"Learner"
                                        }</TableCell> 
                                        <TableCell align="center"> <Tooltip title="Edit" placement="top" onClick={()=>handleEdit(row)} >
                                                <IconButton>
                                                <img src={require("../../asserts/edit.png")} style={{width:"20px"}}/>
                                                </IconButton>
                                        </Tooltip></TableCell>
                                    
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                                </TableContainer>
                                </div>
                        </div>
                    :
                    <div>
                    {  createAccount==true && createGrpAccount==false ?
                        <div className='newCreateAccount' >
                            <div className='accountHeader' style={{alignSelf:"flex-start",paddingLeft:0}}>
                                  <img onClick={()=>{setCreateAccount(false);setOpenPopup(false)}} style={{width:"2rem",marginRight:"20px",cursor:"pointer"}} src={require("../../asserts/leftarrow.png")}/>  <label>Create a new Account</label>
                                    
                            </div>
                            <div className='createNewAccount'>
                                <div style={{display:"flex",width:"100%",justifyContent:"space-between"}} className='newAccountMobile' >
                                    <div className='newAccount'>
                                        <label>Full Name</label>
                                        <input onChange={(e)=>setInvRegName(e.target.value)} type='text' value={invRegName?invRegName:null} placeholder='Michael John'/>
                                    </div>
                                    <div className='newAccount'>
                                        <label>Email</label>
                                        <input type='text' onChange={(e)=>serInvRegEmail(e.target.value)} value={invRegEmail?invRegEmail:null} placeholder='abc@abcdecom'/>
                                    </div>
                                </div>
                                <div style={{display:"flex",width:"100%",justifyContent:"space-between"}} className='newAccountMobile' >
                                    <div className='newAccount'>
                                        <label>School Name</label>
                                        <input onChange={(e)=>setSchoolName(e.target.value)} type='text' value={schoolName?schoolName:null} placeholder='St Johns School'/>
                                    </div>
                                    <div className='newAccount'>
                                        <label>Designation</label>
                                        <input type='text' onChange={(e)=>setDesignation(e.target.value)} value={designation?designation:null} placeholder='Teacher'/>
                                    </div>
                                </div>
                                <div className='newAccount' style={{width:"100%"}}>
                                    <label>Select the Programme to enroll</label>
                                    <select onChange={(e)=>setinvRegProgramme(e.target.value)} value={invRegProgramme?invRegProgramme:null} >
                                        <option>--- Select a Programme ---</option>
                                        {
                                            programmeList.map((data)=>{
                                                return(
                                                    <option key={data.id} value={data.id}>{data.title} ({data.duration} weeks) </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className='createBtn'>
                                    <button onClick={()=>createIndividualAccoutn()}>Create</button>
                                </div>
                            </div>
                        </div>
                    :
                    
                       <>
                       {
                        createAccount==true && createGrpAccount==true ?
                        <div className='newCreateAccount' >
                            <div className='accountHeader' style={{alignSelf:"flex-start",paddingLeft:0}}>
                                  <img onClick={()=>{setCreateAccount(false);setOpenPopup(false)}} style={{width:"2rem",marginRight:"20px",cursor:"pointer"}} src={require("../../asserts/leftarrow.png")}/>  <label>Create a new Group Account</label>
                                    
                            </div>
                            
                            <div className='createNewAccount'>
                                <div style={{display:"flex",width:"100%",justifyContent:"space-between"}}>
                                    <div className='newAccount'>
                                        <label>Select the file to Upload</label>
                                        {/* <input onChange={(e)=>setUploadGrpFile(e.target.files[0])} type='file' /> */}
                                        <div style={{display:"flex",alignItems:"center"}}>
                                        <input style={{alignSelf:"flex-start"}}   onChange={(e)=>setTaskFile(e.target.files[0])} id="grpFile" accept='.csv,.xlsx,.xls' type='file'/>
                                        {
                                            taskfile!=null?
                                            <img onClick={()=>{document.getElementById('grpFile').value=null;setTaskFile(null)}} style={{width:"10px",height:"10px",marginTop:"10px",marginLeft:"10px",cursor:"pointer"}} src={require("../../asserts/close.png")}/>                             
                                            :
                                            <div></div>
                                        }
                                        </div>
                                                  <a
                                        href={SampleFile}
                                        download="Sample_Account_Creation"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                          <button>Download sample file</button>
                                        </a>
                                    </div>
                                    
                                </div>
                                <div className='createBtn'>
                                    <button onClick={()=>uploadBulkRecord()} >Create</button>
                                </div>
                            </div>
                            <div style={{marginTop:"2rem"}}>
                                <label className='header' style={{marginBottom:"1rem"}}>Program Id's</label>
                                <div className='programList'>
                                    <div className='listt header'>Id</div>
                                    <div className='list header'>Program Name</div>
                                </div>
                                {
                                    programmeList.map((data)=>{
                                        return(
                                            <div className='programList'>
                                            <div  className='listt'>{data.id}</div>
                                            <div  className='list'>{data.description}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        :null
                       }  
                        </>       
                    }
                    </div>
                    }
                    </div>
                    }
                </div>
            }
               
            </>
            }
            </>
            :
            <User  navigateBack={navigateBack} />
        }
        </>}
        </div>
    </div>
    </div>
  )
}

export default TeacherDashboard