import { createSlice } from "@reduxjs/toolkit"

export const dataSlice =createSlice({
    name : 'data',
    initialState : {value:{allData:''}},
    reducers:{
        setData:(state,action)=>{
            state.value=action.payload
        }
    }
})
export  const {setData} = dataSlice.actions;
export default dataSlice.reducer;