import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { login } from '../../features/User'
import { setLogin } from "../../features/Login"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThreeCircles } from 'react-loader-spinner'
import "./Login.css"
import environment from '../../environment';
import axios from 'axios';

import { useState, useRef, useEffect } from 'react'
import { useLocation } from 'react-router-dom'


function LoginComponent() {

  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#ffffff");
  const [loader, setLoader] = useState(false);

  const [name, setName] = useState('');
  const [email, setemail] = useState('');
  const [showDD, setShowDD] = useState(true)
  const [password, setpassword] = useState('');
  const [isLogin, setisLogin] = useState(false);

  const [rname, setrName] = useState(null);
  const [rdesignation, setRDesignation] = useState(null);
  const [rSchoolName, setRSchoolName] = useState(null);
  const [rAddress, setRAddress] = useState(null);
  const [rCity, setRCity] = useState(null);
  const [rState, setRState] = useState(null);
  const [rContactNo, setRContactNo] = useState(null);
  const [rSchoolEmail, setRSchoolEmail] = useState(null);
  const [rPersonalEmail, setRPersonalEmail] = useState(null);
  const [bSchoolName, setBSchoolName] = useState(null);
  const [bAddress, setBAddress] = useState(null);
  const [bContactNo, setBConatactNo] = useState(null);
  const [bGST, setBGST] = useState(null);
  const [bPAN, setBPAN] = useState(null);
  const [icourse, seticourse] = useState("-1");
  const [programmeDetailsIds, setProgrammeDetailsIds] = useState([]);
  const [programmeDetailsDuration, setProgrammeDetailsDuration] = useState([]);
  const [Programm, setProgramm] = useState([])
  const location = useLocation();


  const setDuration = (data, index, entire) => {
    let tempData = [...programmeDetailsDuration];
    console.log(tempData)
    let flag = 0;
    tempData.map(temp => {
      if (temp.id == entire.id) {
        temp.duration = data;
        flag = 1;
      }
    })
    if (flag == 0) {
      tempData.push({
        id: entire.id,
        duration: data
      })
    }
    setProgrammeDetailsDuration(tempData)

    test()
  }

  function revertValues() {
    setName(null)
    setemail(null)
    setpassword(null)
    setrName(null)
    setRDesignation(null)
    setRSchoolName(null)
    setRAddress(null)
    setRCity(null)
    setRState(null)
    setRContactNo(null)
    setRSchoolEmail(null)
    setRPersonalEmail(null)
    setBSchoolName(null)
    setBAddress(null)
    setBConatactNo(null)
    setBGST(null)
    setBPAN(null)
    seticourse("-1")
    setProgrammeDetailsIds([])
    setProgrammeDetailsDuration([])
    // setProgramm([])
  }

  function test() {
    console.log(programmeDetailsIds)
  }
  const handleChange = (e, index, data) => {
    // Destructuring
    const { value, checked } = e.target;
    // Case 1 : The user checks the box
    console.log(checked, data.id)
    if (checked) {
      let temp = [...Programm];
      temp.map((data, i) => {
        if (i == index)
          data.checked = 'true'
      })
      setProgramm(temp)
      let programData = [data.id, ...programmeDetailsIds]
      setProgrammeDetailsIds(programData)
    }
    // // Case 2  : The user unchecks the box
    else {
      let temp = [...Programm];
      temp.map((data, i) => {
        if (i == index)
          data.checked = 'false'
      })
      setProgramm(temp)
      let tempDetails = [...programmeDetailsIds]
      tempDetails = tempDetails.filter((val => val != data.id))
      setProgrammeDetailsIds([...tempDetails])

      let tempData = [...programmeDetailsDuration]
      tempData = tempData.filter((details =>
        details.id != data.id))
      setProgrammeDetailsDuration(tempData)

      var t = document.getElementById(data.id + 'inputField')
      t.value = null
    }
    test()
  };

  const [registerType, setregisterType] = useState('Group/School Registration');


  useEffect(() => {
    let data = localStorage.getItem("LMS_DATA")
    if (data) {
      var nameObj = JSON.parse(data);
      console.log(new Date().getTime() - nameObj.time);
      if (new Date().getTime() - nameObj.time >= nameObj.expire) {
        console.log("expired")
        localStorage.removeItem('name')
        dispatch(setLogin({ isLoggedIn: false }))
        navigate('/login')
      }
      else {
        console.log("111", location.pathname)
        if (!location.pathname == "/")
          navigate(location.pathname)
        else {
          console.log("222")
          if (localStorage.getItem("LMS_ROLE") == "Learner")
            navigate("/dashboard")
          else
            navigate("/adminDashboard")

        }
        dispatch(setLogin({ isLoggedIn: true }))
      }
    }
    else {
      console.log("not found")
      dispatch(setLogin({ isLoggedIn: false }))
      navigate('/')
    }
    axios.get(environment.BASE_URL + '/programme').then((response) => {
      let temp = [...response.data.data];
      // let tempdata=[];
      temp.map((data) => {
        data.checked = 'false'
      })
      setProgramm(temp)
      // console.log(tempdata,'aaaaaaaa')
      // setProgrammeDetailsDuration(tempdata)
    }).catch(error => {
      console.error(error);
    });
  }, [])


  const navigate = useNavigate();
  const dispatch = useDispatch();
  window.onscroll = function () { scrollFunction() };
  const ref = useRef(null);
  const moveDown = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }
  function handleLogin() {
    if (email.length == 0 || password.length == 0) {
      toast.error("Please fill the fields")
      return
    }
    setLoader(true)
    let data = {
      email: email,
      password: password
    }

    axios.post(environment.BASE_URL + '/login', data).then((res) => {
      dispatch(login({ name: res.data.data.name, email: email, userId: res.data.data.userId }))
      dispatch(setLogin({ isLoggedIn: true }))
      let storageData = {
        email: email,
        name: res.data.data.name,
        userId: res.data.data.userId,
        expiryTime: 86400000,
        time: new Date().getTime()
      }

      if (res.data.data.role == "Learner") {
        navigate('/dashboard')
      }
      else {
        navigate('/adminDashboard')
      }
      localStorage.setItem("LMS_DATA", JSON.stringify(storageData))
      localStorage.setItem("LMS_ROLE", res.data.data.role)

      setLoader(false)
    }).catch((err) => {
      setLoader(false);
      dispatch(setLogin({ isLoggedIn: false }))
      toast.error(err.response.data.message)
    })



    // toast.success("Invalid Credientials !");

    // navigate('/adminDashboard')
  }
  function handleRegister(event) {
    event.preventDefault()
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (emailRegex.test(rSchoolEmail) && emailRegex.test(rPersonalEmail)) {
    }
    else {
    }
    if ((rContactNo && bContactNo) && (rContactNo.length != 10 || bContactNo.length != 10)) {
      return toast.error("Enter a valid contact number")
    }
    if (rname != null && rSchoolEmail != null && rSchoolName != null && rAddress != null
      && rCity != null && rAddress != null && rCity != null && rState != null && rContactNo != null && rSchoolEmail != null
      && bSchoolName != null && bAddress != null && bContactNo != null && rname.length > 0 && rSchoolEmail.length > 0 && rSchoolName.length > 0 && rAddress.length > 0
      && rCity.length > 0 && rAddress.length > 0 && rCity.length > 0 && rState.length > 0 && rContactNo.length > 0 && rSchoolEmail.length > 0
      && bSchoolName.length > 0 && bAddress.length > 0 && bContactNo.length > 0) {
      if (registerType == 'Group/School Registration' && (programmeDetailsDuration.length == 0 || programmeDetailsIds.length != programmeDetailsDuration.length)) {
        toast.error("Please fill number of Participants details");
        return;
      }
      if (registerType == 'Group/School Registration' && !emailRegex.test(rSchoolEmail)) {
        return toast.error("Enter a valid email")
      }
      if (registerType == 'Individual Registration' && (!emailRegex.test(rSchoolEmail) || !emailRegex.test(rPersonalEmail))) {
        return toast.error("Enter a valid email")
      }
      if (registerType == 'Individual Registration' && (rPersonalEmail.length == 0 || rPersonalEmail == null || icourse == '-1')) {
        if (icourse == '-1')
          toast.error("Please select the Programme ");
        else
          toast.error("Please enter Personal Email Id ");
        return;
      }
      let data;
      let ids = [];
      let number = [];
      setLoader(true)
      programmeDetailsDuration.map((value) => {
        ids.push(value.id);
        number.push(value.duration)
      })
      if (registerType == 'Group/School Registration') {
        data = {
          "name": rname,
          "email": rPersonalEmail,
          "registration_type": 'group',
          "title": "3 months",
          "full_name": rname,
          "school_name": rSchoolName,
          "address": rAddress,
          "designation": rdesignation,
          "city": rCity,
          "state": rState,
          "phone_number": rContactNo,
          "school_email": rSchoolEmail,
          "programme_id": ids,
          "role_id": 3,
          "billing_name": bSchoolName,
          "billing_address": bAddress,
          "billing_contact": bContactNo,
          "gst": bGST,
          "pan": bPAN,
          "no_of_participants": number
        }
      }
      else {
        data = {
          "name": rname,
          "email": rPersonalEmail,
          "registration_type": 'individual',
          "title": "3 months",
          "full_name": rname,
          "school_name": rSchoolName,
          "designation": rdesignation,
          "address": rAddress,
          "city": rCity,
          "state": rState,
          "phone_number": rContactNo,
          "school_email": rSchoolEmail,
          "programme_id": parseInt(icourse),
          "role_id": 3,
          "billing_name": bSchoolName,
          "billing_address": bAddress,
          "billing_contact": bContactNo,
          "gst": bGST,
          "pan": bPAN,
          "no_of_participants": 1
        }
      }
      // setisLogin(true)
      // toast("Registration Successfull")
      axios.post(environment.BASE_URL + '/add-user', data).then((resposne) => {
        console.log("Login Response", resposne)
        toast("Registration Successfull")
        setLoader(false)
        setisLogin(true)
        revertValues()

      }).catch((error) => {
        setLoader(false)
        revertValues()
        console.log("Error Register", error)
        toast.error("Something went wrong. Please try again")
      })
    }
    else {
      setLoader(false)
      toast.error("Please fill the details");
    }
  }
  function backToLogin() {
    setisLogin(true)
  }


  function scrollFunction() {
    if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
      setShowDD(false)
    } else {
      setShowDD(true)
    }
  }
  return (
    <div >
        {
            loader ?
              <div className='loader' style={{zIndex:"10",position:"fixed"}}>
                <ThreeCircles
                  height="100"
                  width="100"
                  color="#4fa94d"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="three-circles-rotating"
                  outerCircleColor=""
                  innerCircleColor=""
                  middleCircleColor=""
                />
              </div> : null
          }
      <ToastContainer autoClose={2000} closeOnClick={true} pauseOnHover={false} />
     
      {isLogin ?
        <div className='container'>
          <div className='Brandlogo'>
            <img src={require('../../asserts/white-logo.png')} className='loginLogoimg' />
          </div>
          <div className='loginContainer' >

            <div style={{ fontSize: '1.2em', fontWeight: "500", marginBottom: '2rem' }}>Log in</div>
            <div className='loginInputFields' >
              <label>Email Address</label>
              <input type='email' name="email" value={email} placeholder='user@user.com' onChange={(event) => setemail(event.target.value)} />
            </div>
            <div className='loginInputFields'>
              <label>Password</label>
              <input type='password' name="password" value={password} placeholder='************' onChange={(event) => setpassword(event.target.value)} />
            </div>
            <div className='loginSubmit'>
              <button className='btn' onClick={() => handleLogin()}>Log in</button>
            </div>
            <div style={{ width: '100%', height: '10px', borderBottom: '1px solid gainsboro', textAlign: 'center', margin: '10px 0' }}>
              <span style={{ fontSize: '12px', backgroundColor: '#CFC6B7', color: 'gainsboro', padding: '0px 10px' }}>
                or
              </span>
            </div>
            <div className='loginSubmit' style={{ marginTop: '5px' }}><button onClick={() => setisLogin(false)}>Register</button></div>
            {/* <div className='loginSubmit' style={{marginTop: '5px'}}><button onClick={()=>setisLogin(false)}>Register</button></div> */}
          </div>
        </div>
        :
        <div className='register'>
          <div className='front'>
            <img style={{ cursor: "pointer", alignSelf: "flex-start", marginTop: ".5rem", marginLeft: ".5rem" }} src={require('../../asserts/white-logo.png')} />
            <p>Hello and Welcome to<b> Cambridge Education Lab Programme </b>registration page. Once you register for the
              programme, you will receive information about next steps and your login credentials in your email.</p>
            {/* <div className={ showDD ? 'downArrow':'hide' } id="downArrow" onClick={()=>moveDown()}><img src={require('../../asserts/down.png')}/></div> */}
            <div className='loginContainer' >

              <div style={{ fontSize: '1.2em', fontWeight: "500", marginBottom: '2rem' }}>Log in</div>
              <div className='loginInputFields' >
                <label>Email Address</label>
                <input type='email' name="email" value={email} placeholder='user@user.com' onChange={(event) => setemail(event.target.value)} />
              </div>
              <div className='loginInputFields'>
                <label>Password</label>
                <input type='password' name="password" value={password} placeholder='************' onChange={(event) => setpassword(event.target.value)} />
              </div>
              <div className='loginSubmit'>
                <button className='btn' onClick={() => handleLogin()}>Log in</button>
              </div>
              <div style={{ width: '100%', height: '10px', borderBottom: '1px solid gainsboro', textAlign: 'center', margin: '10px 0' }}>
                <span style={{ fontSize: '12px', backgroundColor: '#CFC6B7', color: 'gainsboro', padding: '0px 10px' }}>
                  or
                </span>
              </div>
              <div className='loginSubmit' style={{ marginTop: '5px' }}><button onClick={() => moveDown()}>Register</button></div>
              {/* <div className='loginSubmit' style={{marginTop: '5px'}}><button onClick={()=>setisLogin(false)}>Register</button></div> */}
            </div>
          </div>

          <div className='back' ref={ref}>

            <div className='registerContainer' >

              <img src={require('../../asserts/logo.webp.png')} className='loginLogoimg' />
              <div style={{ fontSize: '1.2em', marginBottom: '1rem', width: '100%' }}>Register</div>
              <div className='selectType'>
                <div className={registerType == 'Group/School Registration' ? 'selected types' : 'types'} onClick={() => setregisterType('Group/School Registration')}>Group/School Registration</div>
                <div className={registerType == 'Individual Registration' ? 'selected types' : 'types'} style={{ padding: "20px 20px" }} onClick={() => setregisterType('Individual Registration')}>Individual Registration</div>
              </div>

              <div style={{ width: "100%", display: 'flex', alignItems: "center", flexDirection: "column" }}>
                <div className='groupRegister'>
                  <div className='grLeft'>
                    <p style={{ paddingBottom: '1rem' }}>Organisation Details</p>
                    <div className='loginInputFields' >
                      <label>Your Full Name *</label>
                      <input type='text' name="name" value={rname} placeholder='John' onChange={(event) => setrName(event.target.value)} />
                    </div>
                    <div className='loginInputFields' >
                      <label>Designation *</label>
                      <input type='text' name="text" value={rdesignation} placeholder='Teacher' onChange={(event) => setRDesignation(event.target.value)} />
                    </div>
                    <div className='loginInputFields'>
                      <label>Name of School/Organisation *</label>
                      <input type='text' name="schoolName" value={rSchoolName} placeholder='xxxx xxxx' onChange={(event) => setRSchoolName(event.target.value)} />
                    </div>
                    <div className='loginInputFields'>
                      <label>Address *</label>
                      <input type='text' name="schoolAddress" value={rAddress} placeholder='Address' onChange={(event) => setRAddress(event.target.value)} />
                    </div>
                    <div className='divider'>
                      <div className='loginInputFields' style={{ marginRight: '1rem' }}>
                        <label>City *</label>
                        <input type='text' name="schoolCity" value={rCity} placeholder='Mumbai' onChange={(event) => setRCity(event.target.value)} />
                      </div>
                      <div className='loginInputFields' >
                        <label>State *</label>
                        <input type='text' name="schoolState" value={rState} placeholder='Maharastra' onChange={(event) => setRState(event.target.value)} />
                      </div>

                    </div>
                    <div className='loginInputFields' >
                      <label>Contact Number *</label>
                      <input type='number' name="schoolState" value={rContactNo} placeholder='0000000000' maxLength={10} onChange={(event) => setRContactNo(event.target.value)} />
                    </div>
                    <div className='loginInputFields' >
                      <label>School Email ID *</label>
                      <input type='email' name="schoolState" value={rSchoolEmail} placeholder='abc@gmail.com' onChange={(event) => setRSchoolEmail(event.target.value)} />
                    </div>

                    {registerType != 'Group/School Registration' ?
                      <div className='loginInputFields' >
                        <label>Personal Email ID *</label>
                        <input type='email' name="schoolState" value={rPersonalEmail} placeholder='abc@gmail.com' onChange={(event) => setRPersonalEmail(event.target.value)} />
                      </div>
                      : ''
                    }
                  </div>
                  <div className='grRight'>
                    <p style={{ paddingBottom: '1rem' }}>Billing Details</p>
                    <div className='loginInputFields' >

                      <label>Name of School *</label>
                      <input type='text' name="billName" value={bSchoolName} placeholder='
IIT Mumbai' onChange={(event) => setBSchoolName(event.target.value)} />
                    </div>
                    <div className='loginInputFields' >
                      <label>Address *</label>
                      <input type='text' name="bilAddress" value={bAddress} placeholder='Address' onChange={(event) => setBAddress(event.target.value)} />
                    </div>
                    <div className='loginInputFields'>
                      <label>Contact No *</label>
                      <input type='number' name="billContact" value={bContactNo} placeholder='0000000000' maxLength={10} onChange={(event) => setBConatactNo(event.target.value)} />
                    </div>
                    <div className='loginInputFields'>
                      <label>GST No (if applicable)</label>
                      <input type='text' name="billGst" value={bGST} placeholder='************' onChange={(event) => setBGST(event.target.value)} />
                    </div>
                    <div className='loginInputFields'>
                      <label>PAN No (if applicable)</label>
                      <input type='text' name="billPan" value={bPAN} placeholder='************' onChange={(event) => setBPAN(event.target.value)} />
                    </div>
                  </div>

                </div>
                {registerType == 'Group/School Registration' ?
                  <div className='loginInputFields'>
                    <div style={{display:'flex',justifyContent:"space-between"}}>
                    <label>Select the Programme</label>
                    <label>Enter the number of Teachers</label>
                    </div>
                    {
                      Programm.map((data, index) => {
                        return (
                          <div className='checkboxContainer' key={data.id}>
                            <div className='checkLeft'>
                              <input type='checkbox' name="check1" placeholder='************' value="aaaaa" onChange={(e) => handleChange(e, index, data)} />
                              <p>{data.title} ({data.duration} weeks) </p>
                            </div>
                            <div className='checkLeft'>
                              <input type='number' style={{ minWidth: '2rem' }} disabled={data.checked == 'false'} max={3} min={1} name="password" id={data.id + 'inputField'} placeholder='00' onChange={(event) => setDuration(event.target.value, index, data)} />
                            </div>
                          </div>
                        )
                      })
                    }
                    {/* <div className='checkboxContainer'>
                      <div className='checkLeft'>
                        <input type='checkbox' name="check2"value="bbbb" onChange={handleChange}placeholder='************'/>
                        <p>Teacher training – Adaptive teaching skills (3 months)</p>
                      </div>
                      <div className='checkLeft'>
                      <input type='number' min={0} disabled={!check2} name="password" value={password} placeholder='00' onChange={(event)=>setpassword(event.target.value)}/>
                      </div>
                    </div>
                    <div className='checkboxContainer'>
                      <div className='checkLeft'>
                        <input type='checkbox' name="check3" value="ccccc"  placeholder='************' onChange={handleChange}/>
                        <p>Innovation 10X for School leaders (3 months)</p>
                      </div>
                      <div className='checkLeft'>
                      <input type='number' min={0} disabled={!check3}  name="password" value={password} placeholder='00' onChange={(event)=>setpassword(event.target.value)}/>
                      </div>
                    </div>
                    <div className='checkboxContainer'>
                      <div className='checkLeft'>
                        <input type='checkbox' name="check4" value="ddd" placeholder='************'onChange={handleChange}/>
                        <p>Communication Mastery (3 months)</p>
                      </div>
                      <div className='checkLeft'>
                      <input type='number' min={0} disabled={!check4}   name="password" value={password} placeholder='00' onChange={(event)=>setpassword(event.target.value)}/>
                      </div>
                    </div> */}

                  </div>
                  :
                  <div className='loginInputFields'>
                    <label>Select the Programme</label>
                    <select class="selectpicker form-control" data-live-search="true" value={icourse} onChange={(e) => seticourse(e.target.value)} >
                      <option selected value='-1' > --- Please Select a Programme ---</option>
                      {
                        Programm.map((data) => {
                          return (
                            <option key={data.id} value={data.id}>{data.title} ({data.duration} weeks)</option>
                          )
                        })
                      }
                    </select>
                  </div>
                }
                <div className='loginSubmit'>
                  <button className='btn' onClick={(e) => handleRegister(e)}>Register</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>

  );
}

export default LoginComponent; 