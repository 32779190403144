
import { Route,Routes,useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch} from 'react-redux'
import { useLocation } from 'react-router-dom'
import './App.css';
import LoginComponent from './components/Login/Login';
import DashboardComponent from './components/Dashboard/Dashboard';
import RegisterComponent from './components/Register/Register';
import TeacherDashboard from './components/TeacherDashboard/TeacherDashboard';
import User from './components/User/User';
import { setLogin } from './features/Login';


function App() {
  let dispatch= useDispatch()
  const location = useLocation();
  useEffect(()=>{
    let data= localStorage.getItem("LMS_DATA")
    if(data)
    {
            var nameObj = JSON.parse(data);
            console.log(new Date().getTime() - nameObj.time);
            if(new Date().getTime() - nameObj.time >= nameObj.expire){
              console.log("expired")
                localStorage.removeItem('name')
                dispatch(setLogin({isLoggedIn:false}))
                navigate('/login')
            }
            else{
              console.log("111",location.pathname)
              if(!location.pathname=="/")
                navigate(location.pathname)
              else{
                console.log("222")
                if(localStorage.getItem("LMS_ROLE")=="Learner")
                  navigate("/dashboard")
                else
                  navigate("/adminDashboard")

              }
              dispatch(setLogin({isLoggedIn:true}))
            }
    }
    else{
      console.log("not found")
      dispatch(setLogin({isLoggedIn:false}))
      navigate('/')
    }
  },[])
  const navigate = useNavigate();
  const [loggedIn,setloggedIn]=useState(false)
  setTimeout(()=>{
    setloggedIn(false)
  },5000)
  
  function auth()
  {
    console.log("frmauron")
  }
  return (
    
      <Routes>

        <Route exact path='/' element={<LoginComponent/>} onEnter={auth}/>:
        <Route exact path='/register' element={<RegisterComponent/>}/>
        <Route path='/dashboard' element={<DashboardComponent/>}/>
        <Route path='/adminDashboard' element={<TeacherDashboard/>}/>
        <Route path='/user' element={<User/>} />
      </Routes>
  );
}

export default App;
