import { createSlice } from "@reduxjs/toolkit"

export const edituserSlice =createSlice({
    name : 'editUser',
    initialState : {value:{name:'',email:'',role:'',programme:'',status:''}},
    reducers:{
        editDetails:(state,action)=>{
            state.value=action.payload
        }
    }
})
export  const {editDetails} = edituserSlice.actions;
export default edituserSlice.reducer;