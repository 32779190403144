import React from "react";
 import { ReactDOM } from "react";

 function RegisterComponent ()
 {
    return (
        <div>Register</div>
    )
 }

 export default RegisterComponent;